/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";

const COLOR_CLASS_TO_COLOR_VALUE: Record<ColorClass, ColorValue> = {
  [ColorClass.ArtistPillShadow]: ColorValue.ArtistPillShadow,
  [ColorClass.BackgroundOverlay]: ColorValue.BackgroundOverlay,
  [ColorClass.BackgroundOverlayDark]: ColorValue.BackgroundOverlayDark,
  [ColorClass.BrightPurple]: ColorValue.BrightPurple,
  [ColorClass.CardBackground]: ColorValue.CardBackground,
  [ColorClass.ClickableAreaBackground]: ColorValue.ClickableAreaBackground,
  [ColorClass.DarkCerulean]: ColorValue.DarkCerulean,
  [ColorClass.DarkPurple]: ColorValue.DarkPurple,
  [ColorClass.Dimmer]: ColorValue.Dimmer,
  [ColorClass.Error]: ColorValue.Error,
  [ColorClass.FloatingElement]: ColorValue.FloatingElement,
  [ColorClass.Green]: ColorValue.Green,
  [ColorClass.Ghost]: ColorValue.Ghost,
  [ColorClass.LightBlue]: ColorValue.LightBlue,
  [ColorClass.LightPink]: ColorValue.LightPink,
  [ColorClass.LightPurple]: ColorValue.LightPurple,
  [ColorClass.LightPurpleHover]: ColorValue.LightPurpleHover,
  [ColorClass.LinkWater]: ColorValue.LinkWater,
  [ColorClass.MenuPopup]: ColorValue.MenuPopup,
  [ColorClass.Navy]: ColorValue.Navy,
  [ColorClass.NavyHover]: ColorValue.NavyHover,
  [ColorClass.OverlayButton]: ColorValue.OverlayButton,
  [ColorClass.Pink]: ColorValue.Pink,
  [ColorClass.Primary]: ColorValue.Primary,
  [ColorClass.ProgressBar]: ColorValue.ProgressBar,
  [ColorClass.Purple]: ColorValue.Purple,
  [ColorClass.PurpleGradientHover]: ColorValue.PurpleGradientHover,
  [ColorClass.Red]: ColorValue.Red,
  [ColorClass.RedHover]: ColorValue.RedHover,
  [ColorClass.Secondary]: ColorValue.Secondary,
  [ColorClass.Shader]: ColorValue.Shader,
  [ColorClass.SkeletonBase]: ColorValue.SkeletonBase,
  [ColorClass.SkeletonHighlight]: ColorValue.SkeletonHighlight,
  [ColorClass.SpotlightOverlay]: ColorValue.SpotlightOverlay,
  [ColorClass.Transparent]: ColorValue.Transparent,
  [ColorClass.Tertiary]: ColorValue.Tertiary,
  [ColorClass.WebsiteBackground]: ColorValue.WebsiteBackground,
  [ColorClass.White]: ColorValue.White,
  [ColorClass.Yellow]: ColorValue.Yellow,
  [ColorClass.PhantomPurple]: ColorValue.PhantomPurple,
  [ColorClass.Scheme11]: ColorValue.Scheme11,
  [ColorClass.Scheme12]: ColorValue.Scheme12,
  [ColorClass.Scheme21]: ColorValue.Scheme21,
  [ColorClass.Scheme22]: ColorValue.Scheme22,
  [ColorClass.Scheme31]: ColorValue.Scheme31,
  [ColorClass.Scheme32]: ColorValue.Scheme32,
  [ColorClass.Scheme41]: ColorValue.Scheme41,
  [ColorClass.Scheme42]: ColorValue.Scheme42,
  [ColorClass.Scheme51]: ColorValue.Scheme51,
  [ColorClass.Scheme52]: ColorValue.Scheme52,
  [ColorClass.Scheme61]: ColorValue.Scheme61,
  [ColorClass.Scheme62]: ColorValue.Scheme62,
  [ColorClass.BrightGray]: ColorValue.BrightGray,
  [ColorClass.MediumBlue]: ColorValue.MediumBlue,
  [ColorClass.AliceBlue]: ColorValue.AliceBlue,
  [ColorClass.Sinopia]: ColorValue.Sinopia,
  [ColorClass.Cultured]: ColorValue.Cultured,
  [ColorClass.CadmiumGreen]: ColorValue.CadmiumGreen,
  [ColorClass.Seashell]: ColorValue.Seashell,
  [ColorClass.MaximumRed]: ColorValue.MaximumRed,
  [ColorClass.GreenishGray]: ColorValue.GreenishGray,
  [ColorClass.MidnightBlue]: ColorValue.MidnightBlue,
  [ColorClass.AntiFlashWhite]: ColorValue.AntiFlashWhite,
  [ColorClass.DarkGunmetal]: ColorValue.DarkGunmetal,
  [ColorClass.PopheadzDarkGray]: ColorValue.PopheadzDarkGray,
  [ColorClass.PopheadzLightGray]: ColorValue.PopheadzLightGray,
};

export default COLOR_CLASS_TO_COLOR_VALUE;
