import ResponsivePageBody from "components/containers/ResponsivePageBody";
import { Suspense, useState } from "react";
import CampaignIntroductionModal from "components/pages/campaign/campaign-generic/CampaignIntroductionModal";
import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import getLocalStorage from "utils/local-storage/getLocalStorage";
import LocalStorageKey from "types/enums/LocalStorageKey";
import setLocalStorage from "utils/local-storage/setLocalStorage";
import FlexBox from "components/layout/FlexBox";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import Body1 from "components/text/Body1";
import CampaignGridFullWidth from "components/campaign/CampaignGridFullWidth";
import ColorValue from "types/enums/ColorValue";
import useUserContext from "hooks/useUserContext";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import LoadingSpinner from "components/loading/LoadingSpinner";
import CampaignManageCampaignsCard from "components/pages/campaign/campaign-generic/CampaignManageCampaignsCard";
import CAMPAIGN_STATUSES from "constants/CampaignStatuses";
import {
  CampaignManageCampaignsPageQuery,
  CampaignManageCampaignsPageQuery$data,
} from "components/pages/campaign/campaign-generic/__generated__/CampaignManageCampaignsPageQuery.graphql";
import styles from "css/pages/campaign/campaign-generic/CampaignManageCampaignsPage.module.css";

// Fetch all for now since we don't anticipate hitting this limit for a while
const CAMPAIGNS_FOR_USER_PAGE_SIZE = 200;

const query = graphql`
  query CampaignManageCampaignsPageQuery(
    $after: String
    $first: PaginationAmount!
    $input: CampaignsForUserInput!
  ) {
    CampaignsNamespace {
      campaignsForUser(input: $input) {
        campaigns(after: $after, first: $first, input: $input) {
          edges {
            node {
              id
              ...CampaignManageCampaignsCard_CampaignV2
            }
          }
        }
      }
    }
  }
`;

function Content({
  campaignsData,
}: {
  campaignsData: CampaignManageCampaignsPageQuery$data;
}): JSX.Element {
  const shouldShowCampaignIntroduction = getLocalStorage(
    LocalStorageKey.ShouldShowCampaignIntroduction
  );
  const initialIntroductionModalState = shouldShowCampaignIntroduction
    ? JSON.parse(shouldShowCampaignIntroduction)
    : true;
  const [isIntroductionModalShown, setIsIntroductionModalShown] = useState(
    initialIntroductionModalState
  );

  const hideModal = () => {
    setLocalStorage(LocalStorageKey.ShouldShowCampaignIntroduction, "false");
    setIsIntroductionModalShown(false);
  };
  const { campaigns } = campaignsData.CampaignsNamespace.campaignsForUser;
  const campaignNodes = campaigns?.edges.map(({ node }) => node);

  return (
    <>
      <CampaignIntroductionModal
        isShown={isIntroductionModalShown}
        onHide={hideModal}
      />
      <CampaignGridFullWidth
        className={
          (campaignNodes?.length ?? 0) > 0 ? undefined : styles.centered
        }
      >
        {campaignNodes?.map((node) => (
          <CampaignManageCampaignsCard key={node.id} campaign={node} />
        ))}
      </CampaignGridFullWidth>
    </>
  );
}

function DataLoader() {
  const { user } = useUserContext();
  const data = useLazyLoadQuery<CampaignManageCampaignsPageQuery>(
    query,
    {
      first: CAMPAIGNS_FOR_USER_PAGE_SIZE,
      input: {
        statuses: CAMPAIGN_STATUSES.filter((status) => status !== "Rejected"),
        userId: user!.id,
        viewerId: user!.id,
      },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  return <Content campaignsData={data} />;
}

export default function CampaignManageCampaignsPage(): JSX.Element {
  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <FlexBox flexDirection="column" alignItems="center" gap={64}>
            <FlexBox flexDirection="column" alignItems="center" gap={12}>
              <Header2 colorClass={ColorClass.Primary} textAlign="center">
                Manage Campaigns
              </Header2>
              <Body1 colorClass={ColorClass.Secondary} textAlign="center">
                Campaigns on Formfunction let you sell NFTs to fundraise for
                your dream creative project.
              </Body1>
            </FlexBox>
            <Suspense
              fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
            >
              <DataLoader />
            </Suspense>
          </FlexBox>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
