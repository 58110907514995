/* eslint-disable typescript-sort-keys/string-enum */
import GenericModal from "components/modal/GenericModal";
import Body1 from "components/text/Body1";
import { useEffect, useState } from "react";
import ColorClass from "types/enums/ColorClass";
import styles from "css/modal/CreatorSurveyModal.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import FlexBox from "components/layout/FlexBox";
import Body1Medium from "components/text/Body1Medium";
import RadioButtonWithLabel from "components/buttons/RadioButtonWithLabel";
import assertUnreachable from "formfn-shared/dist/utils/assertUnreachable";
import PrimaryAndSecondaryButtonContainer from "components/buttons/PrimaryAndSecondaryButtonContainer";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import { CreatorSurveyContextProvider } from "context/CreatorSurveyContext";
import useCreatorSurveyContext from "hooks/useCreatorSurveyContext";
import { range } from "formfn-shared/dist/utils/range";
import RadioButton from "components/buttons/RadioButton";
import TextArea from "components/input/TextArea";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { CreatorSurveyModalMutation } from "components/modal/__generated__/CreatorSurveyModalMutation.graphql";
import notifyUnexpectedError from "components/toast/notifyUnexpectedError";
import useBreakpoint from "hooks/useBreakpoint";
import incrementCreatorSurveyDismissals from "utils/local-storage/incrementCreatorSurveyDismissals";
import useViewerId from "hooks/useViewerId";
import ElementId from "types/enums/ElementId";
import CheckboxButtonWithLabel from "components/buttons/CheckboxButtonWithLabel";

const mutation = graphql`
  mutation CreatorSurveyModalMutation($input: SubmitCreatorSurveyInput!) {
    SurveyMutations {
      submitCreatorSurvey(input: $input) {
        user {
          hasTakenCreatorSurvey2023
        }
      }
    }
  }
`;

enum ModalStep {
  End = "end",
  Intro = "intro",
  Page1 = "page1",
  Page2 = "page2",
  Page3 = "page3",
}

// Order matters, this is the order it's displayed in the UI
enum ImprovementOption {
  NewProductFeatures = "New product features",
  Community = "Building community for artists and collectors",
  Marketing = "Help artists with marketing",
  UiUx = "UI/UX (user interface and user experience)",
  Onboarding = "Make onboarding easier",
  Discoverability = "Improve discoverability on Formfunction",
}

// Order matters, this is the order it's displayed in the UI
enum MadeADifferenceOption {
  Positive = "Positive difference",
  Neutral = "Neutral/no difference",
  Negative = "Negative difference",
}

// Order matters, this is the order it's displayed in the UI
enum SeanEllisOption {
  VeryDisappointed = "Very disappointed",
  SomewhatDisappointed = "Somewhat disappointed",
  NotDisappointed = "Not disappointed (it isn't really that useful)",
  Na = "N/A (I no longer use Formfunction)",
}

const MODAL_DESCRIPTIONS: Record<ModalStep, string | JSX.Element> = {
  [ModalStep.End]:
    "Thanks for being a part of our creator community on Formfunction! " +
    "You've been entered into the raffle for a 100,000,000 $BONK prize, " +
    "and you'll be notified if you win.",
  [ModalStep.Intro]: (
    <Body1 colorClass={ColorClass.Secondary} textAlign="center">
      We want to hear from you! Our team is interested in how Formfunction has
      impacted your creative journey, and what we can do better in the future.
      Your answers will be anonymous and will not affect your experience using
      Formfunction.
      <br />
      <br />
      This survey should only take 1-2 minutes. Submit your finished survey to
      enter a raffle for 100,000,000 $BONK!
    </Body1>
  ),
  [ModalStep.Page1]: "Page 1/3",
  [ModalStep.Page2]: "Page 2/3",
  [ModalStep.Page3]: "Page 3/3",
};

function SurveyFlex({ children }: { children: any }) {
  const { isTabletBreakpoint } = useBreakpoint();

  return (
    <FlexBox
      className={styles.surveyFlex}
      flexDirection="column"
      gap={isTabletBreakpoint ? 36 : 48}
    >
      {children}
    </FlexBox>
  );
}

function BinaryChoice({
  firstOptionLabel,
  isFirstOptionActive,
  isSecondOptionActive,
  onClickFirstOption,
  onClickSecondOption,
  secondOptionLabel,
}: {
  firstOptionLabel: string;
  isFirstOptionActive: boolean;
  isSecondOptionActive: boolean;
  onClickFirstOption: () => void;
  onClickSecondOption: () => void;
  secondOptionLabel: string;
}) {
  const { isTabletBreakpoint } = useBreakpoint();

  return (
    <FlexBox flexDirection="column" gap={isTabletBreakpoint ? 0 : 16}>
      <RadioButtonWithLabel
        isActive={isFirstOptionActive}
        label={
          <Body1 colorClass={ColorClass.Primary}>{firstOptionLabel}</Body1>
        }
        onClick={onClickFirstOption}
      />
      <RadioButtonWithLabel
        isActive={isSecondOptionActive}
        label={
          <Body1 colorClass={ColorClass.Primary}>{secondOptionLabel}</Body1>
        }
        onClick={onClickSecondOption}
      />
    </FlexBox>
  );
}

function SurveyQuestion({
  input,
  question,
}: {
  input: JSX.Element;
  question: string;
}) {
  return (
    <FlexBox flexDirection="column" gap={16}>
      <Body1Medium colorClass={ColorClass.Primary}>{question}</Body1Medium>
      {input}
    </FlexBox>
  );
}

function IntroContent({ setStep }: { setStep: (step: ModalStep) => void }) {
  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.PurpleGradient}
      fontClass={FontClass.NavLink}
      onClick={() => setStep(ModalStep.Page1)}
    >
      Let&apos;s go!
    </ButtonWithText>
  );
}

function EndContent({ onHide }: { onHide: () => void }) {
  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.PurpleGradient}
      fontClass={FontClass.NavLink}
      onClick={onHide}
    >
      OK
    </ButtonWithText>
  );
}

function Page1Content({ setStep }: { setStep: (step: ModalStep) => void }) {
  const {
    isFofuBetterThanWeb2,
    isFofuMainRevenue,
    recommend,
    setIsFofuBetterThanWeb2,
    setIsFofuMainRevenue,
    setRecommend,
    setUsesWeb2Platforms,
    usesWeb2Platforms,
  } = useCreatorSurveyContext();

  return (
    <SurveyFlex>
      <SurveyQuestion
        input={
          <BinaryChoice
            firstOptionLabel="Yes"
            isFirstOptionActive={isFofuMainRevenue === true}
            isSecondOptionActive={isFofuMainRevenue === false}
            onClickFirstOption={() => setIsFofuMainRevenue(true)}
            onClickSecondOption={() => setIsFofuMainRevenue(false)}
            secondOptionLabel="No"
          />
        }
        question="Is selling art on Formfunction the main way you make money through your art?"
      />
      <SurveyQuestion
        input={
          <BinaryChoice
            firstOptionLabel="Yes"
            isFirstOptionActive={usesWeb2Platforms === true}
            isSecondOptionActive={usesWeb2Platforms === false}
            onClickFirstOption={() => setUsesWeb2Platforms(true)}
            onClickSecondOption={() => setUsesWeb2Platforms(false)}
            secondOptionLabel="No"
          />
        }
        question="Do you use any web2 platforms to make money through your art? (e.g. Etsy, Patreon, Gumroad, Ko-fi, Instagram Reels)"
      />
      <SurveyQuestion
        input={
          <BinaryChoice
            firstOptionLabel="Formfunction"
            isFirstOptionActive={isFofuBetterThanWeb2 === true}
            isSecondOptionActive={isFofuBetterThanWeb2 === false}
            onClickFirstOption={() => setIsFofuBetterThanWeb2(true)}
            onClickSecondOption={() => setIsFofuBetterThanWeb2(false)}
            secondOptionLabel="Web2 platforms"
          />
        }
        question="Where did you have more success making money through your art?"
      />
      <SurveyQuestion
        input={
          <FlexBox flexWrap="wrap" gap={36} style={{ rowGap: 32 }}>
            {range(1, 11).map((i) => (
              <FlexBox
                alignItems="center"
                flexDirection="column"
                gap={12}
                key={i}
              >
                <Body1Medium colorClass={ColorClass.Primary}>{i}</Body1Medium>
                <RadioButton
                  isActive={i === recommend}
                  onClick={() => setRecommend(i)}
                />
              </FlexBox>
            ))}
          </FlexBox>
        }
        question="On a scale of 1 to 10, how likely is it that you would recommend Formfunction to a friend or a creator (10 being the most likely)?"
      />
      <PrimaryAndSecondaryButtonContainer>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          disabled={
            isFofuMainRevenue == null ||
            usesWeb2Platforms == null ||
            isFofuBetterThanWeb2 == null ||
            recommend == null
          }
          fontClass={FontClass.NavLink}
          onClick={() => setStep(ModalStep.Page2)}
        >
          Next
        </ButtonWithText>
        <TextButton
          buttonThemeOrColorClass={TextButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          onClick={() => setStep(ModalStep.Intro)}
        >
          Go back
        </TextButton>
      </PrimaryAndSecondaryButtonContainer>
    </SurveyFlex>
  );
}

function Page2Content({ setStep }: { setStep: (step: ModalStep) => void }) {
  const {
    positiveDifference,
    setPositiveDifference,
    impact,
    setImpact,
    seanEllis,
    setSeanEllis,
  } = useCreatorSurveyContext();
  const { isTabletBreakpoint } = useBreakpoint();

  return (
    <SurveyFlex>
      <SurveyQuestion
        input={
          <FlexBox flexDirection="column" gap={isTabletBreakpoint ? 0 : 16}>
            {Object.values(MadeADifferenceOption).map((option) => (
              <RadioButtonWithLabel
                key={option}
                isActive={positiveDifference === option}
                label={<Body1 colorClass={ColorClass.Primary}>{option}</Body1>}
                onClick={() => setPositiveDifference(option)}
              />
            ))}
          </FlexBox>
        }
        question="Has Formfunction made a difference in your life?"
      />
      <SurveyQuestion
        input={
          <FlexBox flexDirection="column" gap={isTabletBreakpoint ? 0 : 16}>
            {Object.values(SeanEllisOption).map((option) => (
              <RadioButtonWithLabel
                key={option}
                isActive={seanEllis === option}
                label={<Body1 colorClass={ColorClass.Primary}>{option}</Body1>}
                onClick={() => setSeanEllis(option)}
              />
            ))}
          </FlexBox>
        }
        question="How would you feel if you could no longer use Formfunction?"
      />
      <SurveyQuestion
        input={
          <TextArea
            onChange={setImpact}
            placeholder="We'd love to hear specifics if you have examples!"
            rows={6}
            value={impact}
          />
        }
        question="How has Formfunction impacted your journey as a creator?"
      />
      <PrimaryAndSecondaryButtonContainer>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          disabled={positiveDifference === "" || seanEllis === ""}
          fontClass={FontClass.NavLink}
          onClick={() => setStep(ModalStep.Page3)}
        >
          Next
        </ButtonWithText>
        <TextButton
          buttonThemeOrColorClass={TextButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          onClick={() => setStep(ModalStep.Page1)}
        >
          Go back
        </TextButton>
      </PrimaryAndSecondaryButtonContainer>
    </SurveyFlex>
  );
}

function Page3Content({ setStep }: { setStep: (step: ModalStep) => void }) {
  const {
    competition,
    impact,
    improvements,
    isFofuBetterThanWeb2,
    isFofuMainRevenue,
    positiveDifference,
    problems,
    recommend,
    seanEllis,
    setCompetition,
    setImprovements,
    setProblems,
    usesWeb2Platforms,
  } = useCreatorSurveyContext();
  const [commit, inFlight] = useMutation<CreatorSurveyModalMutation>(mutation);

  return (
    <SurveyFlex>
      <SurveyQuestion
        input={
          <FlexBox flexDirection="column" gap={16}>
            {Object.values(ImprovementOption).map((option) => (
              <CheckboxButtonWithLabel
                isActive={improvements.includes(option)}
                label={<Body1 colorClass={ColorClass.Primary}>{option}</Body1>}
                onClick={() => {
                  if (improvements.includes(option)) {
                    setImprovements(improvements.filter((i) => i !== option));
                  } else {
                    setImprovements([...improvements, option]);
                  }
                }}
                noBorder
              />
            ))}
          </FlexBox>
        }
        question="What could Formfunction do better? (can choose multiple)"
      />
      <SurveyQuestion
        input={
          <TextArea
            onChange={setCompetition}
            placeholder="Write here..."
            rows={6}
            value={competition}
          />
        }
        question="How would you compare Formfunction to other web3 competitors (e.g. other crypto art marketplaces)?"
      />
      <SurveyQuestion
        input={
          <TextArea
            onChange={setProblems}
            placeholder="Write here..."
            rows={6}
            value={problems}
          />
        }
        question="What other creator problems do you wish Formfunction could help with?"
      />
      <PrimaryAndSecondaryButtonContainer>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          disabled={improvements.length === 0}
          fontClass={FontClass.NavLink}
          isLoading={inFlight}
          onClick={() => {
            commit({
              onCompleted: () => {
                setStep(ModalStep.End);
              },
              onError: () => notifyUnexpectedError(),
              variables: {
                input: {
                  competition,
                  impact,
                  improvements: improvements.join(","),
                  isFofuBetterThanWeb2: isFofuBetterThanWeb2!,
                  isFofuMainRevenue: isFofuMainRevenue!,
                  positiveDifference: positiveDifference!,
                  problems,
                  recommend: recommend!,
                  seanEllis,
                  usesWeb2Platforms: usesWeb2Platforms!,
                },
              },
            });
          }}
        >
          Submit!
        </ButtonWithText>
        <TextButton
          buttonThemeOrColorClass={TextButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          onClick={() => setStep(ModalStep.Page2)}
        >
          Go back
        </TextButton>
      </PrimaryAndSecondaryButtonContainer>
    </SurveyFlex>
  );
}

type Props = {
  isShown: boolean;
  modalId: ElementId;
  onHide: () => void;
};

export default function CreatorSurveyModal({
  isShown,
  modalId,
  onHide,
}: Props) {
  const [step, setStep] = useState(ModalStep.Intro);
  const viewerId = useViewerId();
  useEffect(() => {
    const elem = document.getElementById(modalId);
    elem?.scrollTo(0, 0);
  }, [step, modalId]);

  function getContent() {
    switch (step) {
      case ModalStep.End:
        return <EndContent onHide={onHide} />;
      case ModalStep.Intro:
        return <IntroContent setStep={setStep} />;
      case ModalStep.Page1:
        return <Page1Content setStep={setStep} />;
      case ModalStep.Page2:
        return <Page2Content setStep={setStep} />;
      case ModalStep.Page3:
        return <Page3Content setStep={setStep} />;
      default:
        return assertUnreachable(step);
    }
  }

  return (
    <GenericModal
      description={MODAL_DESCRIPTIONS[step]}
      isShown={isShown}
      maxWidth={1000}
      modalId={modalId}
      onHide={() => {
        if (viewerId != null) {
          incrementCreatorSurveyDismissals(viewerId);
        }
        onHide();
      }}
      title={
        step === ModalStep.End ? "Thank you!" : "📣 2023 Creator Survey 📣"
      }
    >
      <CreatorSurveyContextProvider>
        <FlexBox alignItems="center" flexDirection="column">
          {getContent()}
        </FlexBox>
      </CreatorSurveyContextProvider>
    </GenericModal>
  );
}
