/**
 * @generated SignedSource<<4686dfb335d4cbe48602e2e1b6141473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubmitCreatorSurveyInput = {
  competition: string;
  impact: string;
  improvements: string;
  isFofuBetterThanWeb2: boolean;
  isFofuMainRevenue: boolean;
  positiveDifference: string;
  problems: string;
  recommend: number;
  seanEllis: string;
  usesWeb2Platforms: boolean;
};
export type CreatorSurveyModalMutation$variables = {
  input: SubmitCreatorSurveyInput;
};
export type CreatorSurveyModalMutation$data = {
  readonly SurveyMutations: {
    readonly submitCreatorSurvey: {
      readonly user: {
        readonly hasTakenCreatorSurvey2023: boolean | null;
      };
    };
  };
};
export type CreatorSurveyModalMutation = {
  response: CreatorSurveyModalMutation$data;
  variables: CreatorSurveyModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTakenCreatorSurvey2023",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorSurveyModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SurveyMutationsResponse",
        "kind": "LinkedField",
        "name": "SurveyMutations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SubmitCreatorSurveyResponse",
            "kind": "LinkedField",
            "name": "submitCreatorSurvey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserExpress",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatorSurveyModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SurveyMutationsResponse",
        "kind": "LinkedField",
        "name": "SurveyMutations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SubmitCreatorSurveyResponse",
            "kind": "LinkedField",
            "name": "submitCreatorSurvey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserExpress",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5bb1099cc299c1cf8da21aa2ae9b21d8",
    "id": null,
    "metadata": {},
    "name": "CreatorSurveyModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreatorSurveyModalMutation(\n  $input: SubmitCreatorSurveyInput!\n) {\n  SurveyMutations {\n    submitCreatorSurvey(input: $input) {\n      user {\n        hasTakenCreatorSurvey2023\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fabe7f8a5f0a3d9e5f15d6084a11a1e";

export default node;
