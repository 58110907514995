/* eslint-disable typescript-sort-keys/string-enum */
import GenericModal from "components/modal/GenericModal";
import Body1 from "components/text/Body1";
import { useEffect, useState } from "react";
import ColorClass from "types/enums/ColorClass";
import styles from "css/modal/CollectorSurveyModal.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import FlexBox from "components/layout/FlexBox";
import Body1Medium from "components/text/Body1Medium";
import RadioButtonWithLabel from "components/buttons/RadioButtonWithLabel";
import assertUnreachable from "formfn-shared/dist/utils/assertUnreachable";
import PrimaryAndSecondaryButtonContainer from "components/buttons/PrimaryAndSecondaryButtonContainer";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import { CollectorSurveyContextProvider } from "context/CollectorSurveyContext";
import useCollectorSurveyContext from "hooks/useCollectorSurveyContext";
import { range } from "formfn-shared/dist/utils/range";
import RadioButton from "components/buttons/RadioButton";
import TextArea from "components/input/TextArea";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { CollectorSurveyModalMutation } from "components/modal/__generated__/CollectorSurveyModalMutation.graphql";
import notifyUnexpectedError from "components/toast/notifyUnexpectedError";
import useBreakpoint from "hooks/useBreakpoint";
import incrementCollectorSurveyDismissals from "utils/local-storage/incrementCollectorSurveyDismissals";
import useViewerId from "hooks/useViewerId";
import ElementId from "types/enums/ElementId";
import CheckboxButtonWithLabel from "components/buttons/CheckboxButtonWithLabel";

const mutation = graphql`
  mutation CollectorSurveyModalMutation($input: SubmitCollectorSurveyInput!) {
    SurveyMutations {
      submitCollectorSurvey(input: $input) {
        user {
          hasTakenCollectorSurvey2023
        }
      }
    }
  }
`;

enum ModalStep {
  End = "end",
  Intro = "intro",
  Page1 = "page1",
}

// Order matters, this is the order it's displayed in the UI
enum SeanEllisOption {
  VeryDisappointed = "Very disappointed",
  SomewhatDisappointed = "Somewhat disappointed",
  NotDisappointed = "Not disappointed (it isn't really that useful)",
  Na = "N/A (I no longer use Formfunction)",
}

// Order matters, this is the order it's displayed in the UI
enum WhyOption {
  SupportArtists = "To support artists",
  Invest = "To invest in art",
  Fun = "Because it's fun",
  Status = "To gain status",
  Connect = "To connect with artists",
  None = "None of the above",
}

const MODAL_DESCRIPTIONS: Record<ModalStep, string | JSX.Element> = {
  [ModalStep.End]:
    "Thanks for being a part of our collector community on Formfunction! " +
    "You've been entered into the raffle for a 100,000,000 $BONK prize, " +
    "and you'll be notified if you win.",
  [ModalStep.Intro]: (
    <Body1 colorClass={ColorClass.Secondary} textAlign="center">
      We want to hear from you! Our team is interested in how your experience on
      Formfunction has been. Your answers will be anonymous and will not affect
      your experience using Formfunction.
      <br />
      <br />
      This survey should take less than 1 minute. Submit your finished survey to
      enter a raffle for 100,000,000 $BONK!
    </Body1>
  ),
  [ModalStep.Page1]: "Page 1/1",
};

function SurveyFlex({ children }: { children: any }) {
  const { isTabletBreakpoint } = useBreakpoint();

  return (
    <FlexBox
      className={styles.surveyFlex}
      flexDirection="column"
      gap={isTabletBreakpoint ? 36 : 48}
    >
      {children}
    </FlexBox>
  );
}

function SurveyQuestion({
  input,
  question,
}: {
  input: JSX.Element;
  question: string;
}) {
  return (
    <FlexBox flexDirection="column" gap={16}>
      <Body1Medium colorClass={ColorClass.Primary}>{question}</Body1Medium>
      {input}
    </FlexBox>
  );
}

function IntroContent({ setStep }: { setStep: (step: ModalStep) => void }) {
  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.PurpleGradient}
      fontClass={FontClass.NavLink}
      onClick={() => setStep(ModalStep.Page1)}
    >
      Let&apos;s go!
    </ButtonWithText>
  );
}

function EndContent({ onHide }: { onHide: () => void }) {
  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.PurpleGradient}
      fontClass={FontClass.NavLink}
      onClick={onHide}
    >
      OK
    </ButtonWithText>
  );
}

function Page1Content({ setStep }: { setStep: (step: ModalStep) => void }) {
  const {
    freeform,
    setFreeform,
    recommend,
    setRecommend,
    seanEllis,
    setSeanEllis,
    why1,
    setWhy1,
    why2,
    setWhy2,
  } = useCollectorSurveyContext();
  const { isTabletBreakpoint } = useBreakpoint();
  const [commit, inFlight] =
    useMutation<CollectorSurveyModalMutation>(mutation);

  return (
    <SurveyFlex>
      <SurveyQuestion
        input={
          <FlexBox flexDirection="column" gap={isTabletBreakpoint ? 0 : 16}>
            {Object.values(SeanEllisOption).map((option) => (
              <RadioButtonWithLabel
                key={option}
                isActive={seanEllis === option}
                label={<Body1 colorClass={ColorClass.Primary}>{option}</Body1>}
                onClick={() => setSeanEllis(option)}
              />
            ))}
          </FlexBox>
        }
        question="How would you feel if you could no longer use Formfunction?"
      />
      <SurveyQuestion
        input={
          <FlexBox flexWrap="wrap" gap={36} style={{ rowGap: 32 }}>
            {range(1, 11).map((i) => (
              <FlexBox
                alignItems="center"
                flexDirection="column"
                gap={12}
                key={i}
              >
                <Body1Medium colorClass={ColorClass.Primary}>{i}</Body1Medium>
                <RadioButton
                  isActive={i === recommend}
                  onClick={() => setRecommend(i)}
                />
              </FlexBox>
            ))}
          </FlexBox>
        }
        question="On a scale of 1 to 10, how likely is it that you would recommend Formfunction to a friend (10 being the most likely)?"
      />
      <SurveyQuestion
        input={
          <FlexBox flexDirection="column" gap={isTabletBreakpoint ? 0 : 16}>
            {Object.values(WhyOption).map((option) => (
              <CheckboxButtonWithLabel
                isActive={why1.includes(option)}
                label={<Body1 colorClass={ColorClass.Primary}>{option}</Body1>}
                onClick={() => {
                  if (why1.includes(option)) {
                    setWhy1(why1.filter((i) => i !== option));
                  } else {
                    setWhy1([...why1, option]);
                  }
                }}
                noBorder
              />
            ))}
          </FlexBox>
        }
        question="Why do you collect art on Formfunction? (can choose multiple)"
      />
      <SurveyQuestion
        input={
          <TextArea
            onChange={setWhy2}
            placeholder="Write here..."
            rows={3}
            value={why2}
          />
        }
        question="If you collect art for any reason not mentioned above, please describe here."
      />
      <SurveyQuestion
        input={
          <TextArea
            onChange={setFreeform}
            placeholder="Write here..."
            rows={3}
            value={freeform}
          />
        }
        question="If you have any other feedback for us, let us know!"
      />
      <PrimaryAndSecondaryButtonContainer>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          disabled={seanEllis === "" || recommend == null || why1.length === 0}
          fontClass={FontClass.NavLink}
          isLoading={inFlight}
          onClick={() => {
            commit({
              onCompleted: () => {
                setStep(ModalStep.End);
              },
              onError: () => notifyUnexpectedError(),
              variables: {
                input: {
                  freeform,
                  recommend: recommend!,
                  seanEllis,
                  why1: why1.join(","),
                  why2,
                },
              },
            });
          }}
        >
          Submit
        </ButtonWithText>
        <TextButton
          buttonThemeOrColorClass={TextButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          onClick={() => setStep(ModalStep.Intro)}
        >
          Go back
        </TextButton>
      </PrimaryAndSecondaryButtonContainer>
    </SurveyFlex>
  );
}

type Props = {
  isShown: boolean;
  modalId: ElementId;
  onHide: () => void;
};

export default function CollectorSurveyModal({
  isShown,
  modalId,
  onHide,
}: Props) {
  const [step, setStep] = useState(ModalStep.Intro);
  const viewerId = useViewerId();
  useEffect(() => {
    const elem = document.getElementById(modalId);
    elem?.scrollTo(0, 0);
  }, [step, modalId]);

  function getContent() {
    switch (step) {
      case ModalStep.End:
        return <EndContent onHide={onHide} />;
      case ModalStep.Intro:
        return <IntroContent setStep={setStep} />;
      case ModalStep.Page1:
        return <Page1Content setStep={setStep} />;
      default:
        return assertUnreachable(step);
    }
  }

  return (
    <GenericModal
      description={MODAL_DESCRIPTIONS[step]}
      isShown={isShown}
      maxWidth={1000}
      modalId={modalId}
      onHide={() => {
        if (viewerId != null) {
          incrementCollectorSurveyDismissals(viewerId);
        }
        onHide();
      }}
      title={
        step === ModalStep.End ? "Thank you!" : "📣 2023 Collector Survey 📣"
      }
    >
      <CollectorSurveyContextProvider>
        <FlexBox alignItems="center" flexDirection="column">
          {getContent()}
        </FlexBox>
      </CollectorSurveyContextProvider>
    </GenericModal>
  );
}
