import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styles from "css/pages/refunds/RefundsRow.module.css";
import { RefundsRow_RefundableAmount$key } from "components/pages/refunds/__generated__/RefundsRow_RefundableAmount.graphql";
import Video from "components/videos/Video";
import MaybeImgix from "components/images/MaybeImgix";
import Imgix from "react-imgix";
import Header3 from "components/text/Header3";
import ColorClass from "types/enums/ColorClass";
import ButtonWithText from "components/buttons/ButtonWithText";
import FontClass from "types/enums/FontClass";
import ButtonTheme from "types/enums/ButtonTheme";
import { Link } from "react-router-dom";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import invariant from "tiny-invariant";
import { PublicKey } from "@solana/web3.js";
import sendTransactionWithWallet from "utils/solana/misc/sendTransactionWithWallet";
import { notify } from "components/toast/notifications";
import useNftLinkForMetadataAccount from "hooks/useNftLinkForMetadataAccount";
import useFormattedNftPrice from "hooks/useFormattedNftPrice";
import useNftPriceSymbol from "hooks/useNftPriceSymbol";
import useAuctionHouseSdkForPrice from "hooks/useAuctionHouseSdkForPrice";

const fragment = graphql`
  fragment RefundsRow_RefundableAmount on RefundableAmount {
    amount {
      amount
      ...useFormattedNftPrice_Price
      ...useNftPriceSymbol_Price
      ...useAuctionHouseSdkForPrice_Price
    }

    metadataAccount {
      contentType
      mint

      data {
        name
      }

      offchainData {
        image
      }

      ...useNftLinkForMetadataAccount_MetadataAccount
    }
  }
`;

type Props = {
  refundableAmount: RefundsRow_RefundableAmount$key;
};

export default function RefundsRow({ refundableAmount }: Props) {
  const refundableAmountData = useFragment(fragment, refundableAmount);
  const { metadataAccount, amount } = refundableAmountData;
  const { image: src } = metadataAccount.offchainData;
  const { anchorWallet, connection } = useSolanaContext();
  const auctionHouseSdk = useAuctionHouseSdkForPrice(amount);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefunded, setIsRefunded] = useState(false);
  const formattedPrice = useFormattedNftPrice(amount);
  const { shortSymbol, symbol } = useNftPriceSymbol(amount);
  const symbolToUse = shortSymbol ?? symbol;
  const nftLink = useNftLinkForMetadataAccount(metadataAccount);

  return (
    <div className={styles.row}>
      <Link className={styles.assetAndTitle} to={nftLink}>
        {metadataAccount.contentType.includes("video") ? (
          <Video className={styles.image} isMuted src={src} />
        ) : (
          <MaybeImgix src={src}>
            <Imgix
              className={styles.image}
              disableSrcSet
              src={src}
              width={300}
            />
            <img className={styles.image} src={src} />
          </MaybeImgix>
        )}
        <Header3 colorClass={ColorClass.Primary}>
          {metadataAccount.data.name}
        </Header3>
      </Link>

      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        className={styles.refundButton}
        disabled={isRefunded}
        fontClass={FontClass.NavLink}
        isLoading={isLoading}
        onClick={async () => {
          invariant(anchorWallet != null);
          invariant(auctionHouseSdk != null);

          setIsLoading(true);

          const tx = await auctionHouseSdk.withdrawTx(
            {
              receiptAccount: anchorWallet.publicKey,
              tokenMint: new PublicKey(metadataAccount.mint),
              wallet: anchorWallet.publicKey,
            },
            { amount: Number(amount.amount) }
          );

          const txid = await sendTransactionWithWallet({
            commitment: "finalized",
            connection,
            loggingData: {
              transactionType: "Refunded",
            },
            txs: [tx],
            wallet: anchorWallet,
          });

          if (txid == null) {
            setIsLoading(false);
          } else {
            notify({
              duration: 3,
              message: `You were refunded ${formattedPrice} ${symbolToUse}!`,
            });
            setIsLoading(false);
            setIsRefunded(true);
          }
        }}
      >
        {!isRefunded ? `Refund ${formattedPrice} ${symbolToUse}` : `Refunded!`}
      </ButtonWithText>
    </div>
  );
}
