enum AnalyticsEvent {
  AddressLookupTableError = "address_lookup_table_error",
  AntiBotSignerFail = "anti_bot_signer_fail",
  BidModalError = "bid_modal_error",
  BidModalLogRawTxFail = "bid_modal_log_raw_tx_fail",
  BotTaxedTransaction = "bot_taxed_transaction",
  ButtonClick = "button_click",
  BuyEditionError = "buy_edition_error",
  CancelOfferError = "cancel_offer_error",
  ClaimPnftError = "claim_pnft_error",
  ColorModeChange = "color_mode_change",
  ConnectWallet = "connect_wallet",
  CopyTextToClipboardError = "copy_text_to_clipboard_error",
  DiscordAuthRetryLimitReached = "discord_auth_retry_limit_reached",
  ExchangeRatesError = "exchange_rates_error",
  GlobalErrorBoundaryHit = "global_error_boundary_hit",
  GumdropSdkNull = "gumdrop_sdk_null",
  InsertRawTxFail = "insert_raw_tx_fail",
  InsertRawTxSerializeExtraDataFail = "insert_raw_tx_serialize_extra_data_fail",
  LaunchDarklyError = "launch_darkly_error",
  LoginFirebaseError = "login_firebase_error",
  LoginFirebaseSuccess = "login_firebase_success",
  MakeAnOfferModalError = "make_an_offer_modal_error",
  MintNftError = "mint_nft_error",
  PageView = "page_view",
  RelayFetchFail = "relay_fetch_fail",
  RelayFetchFailPolling = "relay_fetch_fail_polling",
  RelayFetchThrows = "relay_fetch_throws",
  RelayFetchThrowsPolling = "relay_fetch_throws_polling",
  RelayUnexpectedUndefined = "relay_unexpected_undefined",
  RelayUpdaterError = "relay_updater_error",
  RpcError = "rpc_error",
  SeriesInvalidSlug = "series_invalid_slug",
  TooManyCreators = "too_many_creators",
  TopLevelEvent = "top_level_event",
  TransactionTooLarge = "transaction_too_large",
  UnknownWalletType = "unknown_wallet_type",
  VerificationError = "verification_error",
  WaitingForLastBidPrice = "waiting_for_last_bid_price",
}

export default AnalyticsEvent;
