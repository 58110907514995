import Body1 from "components/text/Body1";
import Header2 from "components/text/Header2";
import styles from "css/pages/update-metadata/UpdateMetadataHeader.module.css";
import ColorClass from "types/enums/ColorClass";

export default function UpdateMetadataHeader() {
  return (
    <div className={styles.container}>
      <Header2 colorClass={ColorClass.Primary} textAlign="center">
        Update Metadata
      </Header2>
      <Body1
        className={styles.description}
        colorClass={ColorClass.Secondary}
        textAlign="center"
      >
        We noticed that some platforms don&apos;t properly display videos minted
        on Formfunction (e.g. NftEyez). This page lets you update the metadata
        of your video NFTs so that they will be properly displayed across all
        platforms.
        <br />
        <br />
        Please be patient, updating the metadata may take a while.
      </Body1>
    </div>
  );
}
