import {
  CollectorSurveyContext,
  CollectorSurveyContextData,
} from "context/CollectorSurveyContext";

import { useContext } from "react";

export default function useCollectorSurveyContext(): CollectorSurveyContextData {
  return useContext(CollectorSurveyContext);
}
