import graphql from "babel-plugin-relay/macro";
import NftGridFullWidth from "components/grids/nft/NftGridFullWidth";
import UpdateMetadataListingCard from "components/pages/update-metadata/UpdateMetadataListingCard";
import { UpdateMetadataBodyOnPlatformQuery } from "components/pages/update-metadata/__generated__/UpdateMetadataBodyOnPlatformQuery.graphql";
import Header3 from "components/text/Header3";
import useUserContext from "hooks/useUserContext";
import { useLazyLoadQuery } from "react-relay";
import ColorClass from "types/enums/ColorClass";

const onPlatformQuery = graphql`
  query UpdateMetadataBodyOnPlatformQuery(
    $after: String
    $first: PaginationAmount!
    $input: MetadataAccountsCreatedInput!
  ) {
    metadataAccountsCreated {
      metadataAccounts(after: $after, first: $first, input: $input) {
        edges {
          node {
            id
            contentType

            nft {
              creatorId
            }

            ...UpdateMetadataListingCard_MetadataAccount
          }
        }
      }
    }
  }
`;

export default function UpdateMetadataBody() {
  const { user } = useUserContext();
  const data = useLazyLoadQuery<UpdateMetadataBodyOnPlatformQuery>(
    onPlatformQuery,
    {
      after: null,
      first: 200,
      input: {
        creatorAddress: user!.id,
      },
    }
  );

  const dataFiltered =
    data.metadataAccountsCreated.metadataAccounts.edges.filter(
      ({ node }) =>
        node.contentType.includes("video") && node.nft.creatorId === user!.id
    );

  if (dataFiltered.length === 0) {
    return (
      <Header3 colorClass={ColorClass.Primary} textAlign="center">
        No NFTs to update
      </Header3>
    );
  }

  return (
    <NftGridFullWidth>
      {dataFiltered.map(({ node }) => (
        <UpdateMetadataListingCard key={node.id} metadataAccount={node} />
      ))}
    </NftGridFullWidth>
  );
}
