import { Context, createContext, useMemo, useState } from "react";
import emptyFunction from "formfn-shared/dist/utils/emptyFunction";
import { Maybe } from "formfn-shared/dist/types/UtilityTypes";

export type CreatorSurveyContextData = {
  competition: string;
  impact: string;
  improvements: Array<string>;
  isFofuBetterThanWeb2: Maybe<boolean>;
  isFofuMainRevenue: Maybe<boolean>;
  positiveDifference: string;
  problems: string;
  recommend: Maybe<number>;
  seanEllis: string;
  setCompetition: (val: string) => void;
  setImpact: (val: string) => void;
  setImprovements: (val: Array<string>) => void;
  setIsFofuBetterThanWeb2: (val: boolean) => void;
  setIsFofuMainRevenue: (val: boolean) => void;
  setPositiveDifference: (val: string) => void;
  setProblems: (val: string) => void;
  setRecommend: (val: number) => void;
  setSeanEllis: (val: string) => void;
  setUsesWeb2Platforms: (val: boolean) => void;
  usesWeb2Platforms: Maybe<boolean>;
};

export const CreatorSurveyContext: Context<CreatorSurveyContextData> =
  createContext<CreatorSurveyContextData>({
    competition: "",
    impact: "",
    improvements: [],
    isFofuBetterThanWeb2: null,
    isFofuMainRevenue: null,
    positiveDifference: "",
    problems: "",
    recommend: null,
    seanEllis: "",
    setCompetition: emptyFunction,
    setImpact: emptyFunction,
    setImprovements: emptyFunction,
    setIsFofuBetterThanWeb2: emptyFunction,
    setIsFofuMainRevenue: emptyFunction,
    setPositiveDifference: emptyFunction,
    setProblems: emptyFunction,
    setRecommend: emptyFunction,
    setSeanEllis: emptyFunction,
    setUsesWeb2Platforms: emptyFunction,
    usesWeb2Platforms: null,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function CreatorSurveyContextProvider(
  props: ProviderProps
): JSX.Element {
  const [competition, setCompetition] = useState<string>("");
  const [impact, setImpact] = useState<string>("");
  const [improvements, setImprovements] = useState<Array<string>>([]);
  const [problems, setProblems] = useState<string>("");
  const [isFofuBetterThanWeb2, setIsFofuBetterThanWeb2] =
    useState<Maybe<boolean>>(null);
  const [isFofuMainRevenue, setIsFofuMainRevenue] =
    useState<Maybe<boolean>>(null);
  const [positiveDifference, setPositiveDifference] = useState<string>("");
  const [recommend, setRecommend] = useState<Maybe<number>>(null);
  const [seanEllis, setSeanEllis] = useState<string>("");
  const [usesWeb2Platforms, setUsesWeb2Platforms] =
    useState<Maybe<boolean>>(null);

  const value = useMemo(
    () => ({
      competition,
      impact,
      improvements,
      isFofuBetterThanWeb2,
      isFofuMainRevenue,
      positiveDifference,
      problems,
      recommend,
      seanEllis,
      setCompetition,
      setImpact,
      setImprovements,
      setIsFofuBetterThanWeb2,
      setIsFofuMainRevenue,
      setPositiveDifference,
      setProblems,
      setRecommend,
      setSeanEllis,
      setUsesWeb2Platforms,
      usesWeb2Platforms,
    }),
    [
      competition,
      impact,
      improvements,
      isFofuBetterThanWeb2,
      isFofuMainRevenue,
      positiveDifference,
      problems,
      recommend,
      seanEllis,
      usesWeb2Platforms,
    ]
  );

  return (
    <CreatorSurveyContext.Provider value={value}>
      {props.children}
    </CreatorSurveyContext.Provider>
  );
}
