import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import UpdateMetadataBody from "components/pages/update-metadata/UpdateMetadataBody";
import UpdateMetadataHeader from "components/pages/update-metadata/UpdateMetadataHeader";
import { Suspense } from "react";
import ColorValue from "types/enums/ColorValue";
import styles from "css/pages/update-metadata/UpdateMetadataPage.module.css";
import useUserContext from "hooks/useUserContext";
import Page404 from "components/pages/errors/Page404";

export default function UpdateMetadataPage() {
  const { user } = useUserContext();

  if (user != null && !user.isWhitelisted) {
    return <Page404 />;
  }

  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <UpdateMetadataHeader />
          <div className={styles.body}>
            <Suspense
              fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
            >
              <UpdateMetadataBody />
            </Suspense>
          </div>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
