/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorClass {
  AliceBlue = "colorAliceBlue",
  AntiFlashWhite = "colorAntiFlashWhite",
  ArtistPillShadow = "colorArtistPillShadow",
  BackgroundOverlay = "colorBackgroundOverlay",
  BackgroundOverlayDark = "colorBackgroundOverlayDark",
  BrightGray = "colorBrightGray",
  BrightPurple = "colorBrightPurple",
  CadmiumGreen = "colorCadmiumGreen",
  CardBackground = "colorCardBackground",
  ClickableAreaBackground = "colorClickableAreaBackground",
  Cultured = "colorCultured",
  DarkCerulean = "colorDarkCerulean",
  DarkGunmetal = "colorDarkGunmetal",
  DarkPurple = "colorDarkPurple",
  Dimmer = "colorDimmer",
  Error = "colorError",
  FloatingElement = "colorFloatingElement",
  Ghost = "colorGhost",
  Green = "colorGreen",
  GreenishGray = "colorGreenishGray",
  LightBlue = "colorLightBlue",
  LightPink = "colorLightPink",
  LightPurple = "colorLightPurple",
  LightPurpleHover = "colorLightPurpleHover",
  LinkWater = "colorLinkWater",
  MaximumRed = "colorMaximumRed",
  MediumBlue = "colorMediumBlue",
  MenuPopup = "colorMenuPopup",
  MidnightBlue = "colorMidnightBlue",
  Navy = "colorNavy",
  NavyHover = "colorNavyHover",
  OverlayButton = "colorOverlayButton",
  PhantomPurple = "colorPhantomPurple",
  Pink = "colorPink",
  PopheadzDarkGray = "colorPopheadzDarkGray",
  PopheadzLightGray = "colorPopheadzLightGray",
  Primary = "colorPrimary",
  ProgressBar = "colorProgressBar",
  Purple = "colorPurple",
  PurpleGradientHover = "colorPurpleGradientHover",
  Red = "colorRed",
  RedHover = "colorRedHover",
  Scheme11 = "colorScheme11",
  Scheme12 = "colorScheme12",
  Scheme21 = "colorScheme21",
  Scheme22 = "colorScheme22",
  Scheme31 = "colorScheme31",
  Scheme32 = "colorScheme32",
  Scheme41 = "colorScheme41",
  Scheme42 = "colorScheme42",
  Scheme51 = "colorScheme51",
  Scheme52 = "colorScheme52",
  Scheme61 = "colorScheme61",
  Scheme62 = "colorScheme62",
  Seashell = "colorSeashell",
  Secondary = "colorSecondary",
  Shader = "colorShader",
  Sinopia = "colorSinopia",
  SkeletonBase = "colorSkeletonBase",
  SkeletonHighlight = "colorSkeletonHighlight",
  SpotlightOverlay = "colorSpotlightOverlay",
  Tertiary = "colorTertiary",
  Transparent = "colorTransparent",
  WebsiteBackground = "colorWebsiteBackground",
  White = "colorWhite",
  Yellow = "colorYellow",
}

export default ColorClass;
