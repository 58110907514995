import dayjs from "utils/dates/dayjsex";
import styles from "css/pages/safety-check/SafetyCheckPage.module.css";
import graphql from "babel-plugin-relay/macro";
import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import SafetyCheckForm from "components/pages/safety-check/SafetyCheckForm";
import {
  SafetyCheckPageQuery,
  SafetyCheckPageQuery$data,
} from "components/pages/safety-check/__generated__/SafetyCheckPageQuery.graphql";
import Body1 from "components/text/Body1";
import useViewerId from "hooks/useViewerId";
import { Suspense, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import getImgixUrl from "utils/getImgixUrl";
import getSafetyCheckReopenDate from "formfn-shared/dist/utils/safety-check/getSafetyCheckReopenDate";
import { Dayjs } from "dayjs";
import { Maybe, MaybeUndef } from "formfn-shared/dist/types/UtilityTypes";

const TIMELINE = "3-4 days";

const DESCRIPTIONS = {
  default: (
    <Body1 colorClass={ColorClass.Secondary} textAlign="center">
      Before being able to mint on Formfunction, we require all creators to fill
      out this Safety Check to help us keep Formfunction a trustworthy platform.
      This helps us verify that you are the actual creator of the work you plan
      to mint. We do not reject anyone based on quality of work.
      <br />
      <br />
      The timeline to a decision may vary depending on the volume of
      submissions, but usually you will hear back from us through email within
      {TIMELINE} on whether you have passed the Safety Check.
    </Body1>
  ),
  rejected: ({ timeOfRejection }: { timeOfRejection: Dayjs }) => (
    <Body1 colorClass={ColorClass.Secondary} textAlign="center">
      Unfortunately, your submission was not approved.
      <br />
      <br />
      This submission form will re-open on{" "}
      {getSafetyCheckReopenDate(timeOfRejection).format("MMMM D, YYYY")}.
    </Body1>
  ),
  submitted: (
    <Body1 colorClass={ColorClass.Primary}>
      You should hear back from us via email in {TIMELINE}.
    </Body1>
  ),
  whitelisted: null,
};

const HEADERS = {
  default: "Safety Check",
  rejected: "Thanks for applying to Formfunction!",
  submitted: "Your submission has been submitted!",
  whitelisted: "You are already a verified creator!",
};

const query = graphql`
  query SafetyCheckPageQuery($id: String!) {
    User_by_pk(id: $id) {
      # eslint-disable-next-line relay/unused-fields
      id
      isWhitelisted

      ArtistSubmissions(order_by: { timeCreated: desc }) {
        # eslint-disable-next-line relay/unused-fields
        id
        status

        Votes(
          where: { voteType: { _in: [PrescreenReject, Reject] } }
          order_by: { timeCreated: desc }
          limit: 1
        ) {
          # eslint-disable-next-line relay/unused-fields
          id
          timeCreated
        }
      }

      ...SafetyCheckForm_User
    }
  }
`;

function getContentFromData(
  data: SafetyCheckPageQuery$data,
  onSubmitted: () => void
): {
  body?: MaybeUndef<JSX.Element>;
  description: Maybe<JSX.Element>;
  header: string;
} {
  const imageBody = (
    <img
      className={styles.submittedImage}
      src={getImgixUrl("illustrations/no-auctions-cropped.png")}
    />
  );

  if (data.User_by_pk?.isWhitelisted) {
    return {
      body: imageBody,
      description: DESCRIPTIONS.whitelisted,
      header: HEADERS.whitelisted,
    };
  }

  const artistSubmissionArr = data.User_by_pk?.ArtistSubmissions ?? [];
  const artistSubmission =
    artistSubmissionArr.length > 0 ? artistSubmissionArr[0] : null;

  if (artistSubmission != null && artistSubmission.status !== "Rejected") {
    return {
      body: imageBody,
      description: DESCRIPTIONS.submitted,
      header: HEADERS.submitted,
    };
  }

  if (artistSubmission?.status === "Rejected") {
    const timeOfRejection = dayjs(artistSubmission.Votes[0].timeCreated);
    const reopenDate = getSafetyCheckReopenDate(timeOfRejection);

    if (dayjs().isBefore(reopenDate)) {
      return {
        body: imageBody,
        description: DESCRIPTIONS.rejected({ timeOfRejection }),
        header: HEADERS.rejected,
      };
    }
  }

  return {
    body: <SafetyCheckForm onSubmitted={onSubmitted} user={data.User_by_pk!} />,
    description: DESCRIPTIONS.default,
    header: HEADERS.default,
  };
}

function QueryLoader() {
  const viewerId = useViewerId();
  const data = useLazyLoadQuery<SafetyCheckPageQuery>(query, {
    id: viewerId!,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const content = getContentFromData(data, () => setIsSubmitted(true));

  return (
    <ResponsivePageBody
      description={
        isSubmitted ? DESCRIPTIONS.submitted : content.description ?? undefined
      }
      header={isSubmitted ? HEADERS.submitted : content.header}
    >
      {isSubmitted ? (
        <img
          className={styles.submittedImage}
          src={getImgixUrl("illustrations/no-auctions-cropped.png")}
        />
      ) : (
        content.body
      )}
    </ResponsivePageBody>
  );
}

export default function SafetyCheckPage() {
  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <Suspense
          fallback={
            <ResponsivePageBody>
              <LoadingSpinner colorValue={ColorValue.BrightPurple} />
            </ResponsivePageBody>
          }
        >
          <QueryLoader />
        </Suspense>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
