/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum BackgroundColorClass {
  AliceBlue = "backgroundColorAliceBlue",
  AntiFlashWhite = "backgroundColorAntiFlashWhite",
  ArtistPillShadow = "backgroundColorArtistPillShadow",
  BackgroundOverlay = "backgroundColorBackgroundOverlay",
  BackgroundOverlayDark = "backgroundColorBackgroundOverlayDark",
  BlueGradient = "backgroundColorBlueGradient",
  BrightGray = "backgroundColorBrightGray",
  BrightPurple = "backgroundColorBrightPurple",
  CadmiumGreen = "backgroundColorCadmiumGreen",
  CardBackground = "backgroundColorCardBackground",
  ClickableAreaBackground = "backgroundColorClickableAreaBackground",
  Cultured = "backgroundColorCultured",
  DarkCerulean = "backgroundColorDarkCerulean",
  DarkGunmetal = "backgroundColorDarkGunmetal",
  DarkPurple = "backgroundColorDarkPurple",
  Dimmer = "backgroundColorDimmer",
  Error = "backgroundColorError",
  FloatingElement = "backgroundColorFloatingElement",
  Footer = "backgroundColorFooter",
  Ghost = "backgroundColorGhost",
  Green = "backgroundColorGreen",
  GreenGradient = "backgroundColorGreenGradient",
  GreenishGray = "backgroundColorGreenishGray",
  LightBlue = "backgroundColorLightBlue",
  LightPink = "backgroundColorLightPink",
  LightPurple = "backgroundColorLightPurple",
  LightPurpleGradient = "backgroundColorLightPurpleGradient",
  LightPurpleHover = "backgroundColorLightPurpleHover",
  LightPurpleVerticalGradient = "backgroundColorLightPurpleVerticalGradient",
  LinkWater = "backgroundColorLinkWater",
  MaximumRed = "backgroundColorMaximumRed",
  MediumBlue = "backgroundColorMediumBlue",
  MenuPopup = "backgroundColorMenuPopup",
  MidnightBlue = "backgroundColorMidnightBlue",
  Navy = "backgroundColorNavy",
  NavyHover = "backgroundColorNavyHover",
  OverlayButton = "backgroundColorOverlayButton",
  PhantomPurple = "backgroundColorPhantomPurple",
  Pink = "backgroundColorPink",
  PinkVerticalGradient = "backgroundColorPinkVerticalGradient",
  PopheadzDarkGray = "backgroundColorPopheadzDarkGray",
  PopheadzLightGray = "backgroundColorPopheadzLightGray",
  Primary = "backgroundColorPrimary",
  ProgressBar = "backgroundColorProgressBar",
  Purple = "backgroundColorPurple",
  PurpleGradient = "backgroundColorPurpleGradient",
  PurpleGradientForText = "backgroundColorPurpleGradientForText",
  PurpleGradientHover = "backgroundColorPurpleGradientHover",
  Red = "backgroundColorRed",
  RedGradient = "backgroundColorRedGradient",
  RedHover = "backgroundColorRedHover",
  Scheme11 = "backgroundColorScheme11",
  Scheme12 = "backgroundColorScheme12",
  Scheme21 = "backgroundColorScheme21",
  Scheme22 = "backgroundColorScheme22",
  Scheme31 = "backgroundColorScheme31",
  Scheme32 = "backgroundColorScheme32",
  Scheme41 = "backgroundColorScheme41",
  Scheme42 = "backgroundColorScheme42",
  Scheme51 = "backgroundColorScheme51",
  Scheme52 = "backgroundColorScheme52",
  Scheme61 = "backgroundColorScheme61",
  Scheme62 = "backgroundColorScheme62",
  Seashell = "backgroundColorSeashell",
  Secondary = "backgroundColorSecondary",
  Shader = "backgroundColorShader",
  Sinopia = "backgroundColorSinopia",
  SkeletonBase = "backgroundColorSkeletonBase",
  SkeletonHighlight = "backgroundColorSkeletonHighlight",
  SpotlightOverlay = "backgroundColorSpotlightOverlay",
  Tertiary = "backgroundColorTertiary",
  Transparent = "backgroundColorTransparent",
  WebsiteBackground = "backgroundColorWebsiteBackground",
  White = "backgroundColorWhite",
  Yellow = "backgroundColorYellow",
}

export default BackgroundColorClass;
