import SvgSquareIconProps from "types/SvgSquareIconProps";

export default function AuctionIcon({
  colorValue,
  size,
}: SvgSquareIconProps): JSX.Element {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4887 18.4499H0.765905C0.492193 18.4499 0.239349 18.5977 0.102578 18.8374C-0.0341927 19.0772 -0.0341927 19.3727 0.102578 19.6125C0.239349 19.8522 0.492202 20 0.765905 20H11.4887C11.7624 20 12.0153 19.8523 12.1521 19.6125C12.2888 19.3727 12.2888 19.0772 12.1521 18.8374C12.0153 18.5977 11.7624 18.4499 11.4887 18.4499Z"
        fill={colorValue}
      />
      <path
        d="M3.26591 17.0501H9.05006C9.32378 17.0501 9.57662 16.9023 9.71339 16.6626C9.85016 16.4228 9.85016 16.1273 9.71339 15.8875C9.57662 15.6477 9.32377 15.5 9.05006 15.5H3.26591C2.99219 15.5 2.73935 15.6477 2.60258 15.8875C2.46581 16.1273 2.46581 16.4228 2.60258 16.6626C2.73935 16.9023 2.9922 17.0501 3.26591 17.0501Z"
        fill={colorValue}
      />
      <path
        d="M19.3776 13.1127L13.6996 7.36846L15.4326 5.61295C15.8597 5.17982 16.0996 4.59267 16.0996 3.98046C16.0996 3.36824 15.8597 2.78111 15.4326 2.34797L13.7603 0.653972C13.3261 0.234252 12.7491 0 12.1487 0C11.5486 0 10.9714 0.234265 10.5374 0.653972L9.55682 1.6474L4.09168 7.18348C3.66479 7.61661 3.42468 8.20375 3.42468 8.81579C3.42468 9.42801 3.6646 10.0151 4.09151 10.4483L5.76381 12.1423C6.19138 12.5749 6.77101 12.8179 7.37538 12.8179C7.97975 12.8179 8.55936 12.5749 8.98695 12.1423L10.72 10.3868L16.3906 16.1464C16.7867 16.5475 17.3241 16.7727 17.8842 16.7727C18.4443 16.7727 18.9817 16.5475 19.3778 16.1464C19.7762 15.7447 20 15.1988 20 14.6294C20 14.0601 19.776 13.5143 19.3776 13.1127ZM11.632 1.73954C11.7744 1.5962 11.9671 1.51559 12.1679 1.51559C12.3688 1.51559 12.5613 1.5962 12.7039 1.73954L14.3762 3.43354C14.5177 3.57791 14.5972 3.77299 14.5972 3.9765C14.5972 4.17983 14.5177 4.37508 14.3762 4.51928L13.9124 4.97354L11.1683 2.1938L11.632 1.73954ZM10.1118 3.27954L12.8559 6.05928L9.29855 9.66279L6.53154 6.90628L10.1118 3.27954ZM7.90738 11.0565C7.76502 11.2001 7.57228 11.2805 7.37155 11.2805C7.17066 11.2805 6.97808 11.2001 6.83555 11.0565L5.16325 9.36254C5.02175 9.21834 4.94217 9.02309 4.94217 8.81975C4.94217 8.61625 5.02175 8.42118 5.16325 8.27679L5.45967 7.97653L8.2038 10.7563L7.90738 11.0565ZM18.2986 15.0528C18.0641 15.2778 17.6969 15.2778 17.4625 15.0528L11.7919 9.30859L12.628 8.46159L18.2986 14.2058C18.5285 14.4401 18.5285 14.8185 18.2986 15.0528Z"
        fill={colorValue}
      />
    </svg>
  );
}
