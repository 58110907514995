import axios from "axios";
import graphql from "babel-plugin-relay/macro";
import ListingCardForMetadata from "components/auction/ListingCardForMetadata";
import { UpdateMetadataListingCard_MetadataAccount$key } from "components/pages/update-metadata/__generated__/UpdateMetadataListingCard_MetadataAccount.graphql";
import { useEffect, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import VideoQuality from "types/enums/VideoQuality";
import styles from "css/pages/update-metadata/UpdateMetadataListingCard.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import useSolanaContext from "hooks/useSolanaContext";
import invariant from "tiny-invariant";
import sendTransactionWithWallet from "utils/solana/misc/sendTransactionWithWallet";
import getRestUrl from "utils/env/getRestUrl";
import { UpdateMetadataListingCardMutation } from "components/pages/update-metadata/__generated__/UpdateMetadataListingCardMutation.graphql";
import {
  createUpdateMetadataAccountInstruction,
  Metadata,
} from "@metaplex-foundation/mpl-token-metadata";
import { PublicKey } from "@solana/web3.js";
import notifyUnexpectedError from "components/toast/notifyUnexpectedError";
import ixToTx from "formfn-shared/dist/utils/solana/ix/ixToTx";
import findTokenMetadata from "utils/solana/pdas/findTokenMetadata";

const fragment = graphql`
  fragment UpdateMetadataListingCard_MetadataAccount on MetadataAccount {
    data {
      uri
    }
    mint

    ...ListingCardForMetadata_MetadataAccount
  }
`;

const mutation = graphql`
  mutation UpdateMetadataListingCardMutation(
    $set: NftMetadata_set_input!
    $pk_columns: NftMetadata_pk_columns_input!
  ) {
    update_NftMetadata_by_pk(_set: $set, pk_columns: $pk_columns) {
      id
    }
  }
`;

type Props = {
  metadataAccount: UpdateMetadataListingCard_MetadataAccount$key;
};

export default function UpdateMetadataListingCard({ metadataAccount }: Props) {
  const [commit] = useMutation<UpdateMetadataListingCardMutation>(mutation);
  const { anchorWallet, connection } = useSolanaContext();
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const [isUpdated, setIsUpdated] = useState(false);
  const [offchainMetadata, setOffchainMetadata] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { uri } = metadataAccountData.data;
  useEffect(() => {
    async function run() {
      const offchainMetadataInner = await axios.get(uri);

      const { properties } = offchainMetadataInner.data;
      setIsUpdated("category" in properties || properties.files.length < 2);
      setOffchainMetadata(offchainMetadataInner);
    }

    run();
  }, [uri]);

  return (
    <div className={styles.container}>
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        className={styles.button}
        disabled={isUpdated || offchainMetadata == null}
        fontClass={FontClass.NavLink}
        isLoading={offchainMetadata == null || isLoading}
        onClick={async () => {
          invariant(anchorWallet != null);
          invariant(connection != null);

          setIsLoading(true);

          const [metadataPda] = await findTokenMetadata(
            new PublicKey(metadataAccountData.mint)
          );
          const metadata = await Metadata.fromAccountAddress(
            connection,
            metadataPda
          );
          const metadataData = metadata.data;

          const newUriResponse = await axios.post(
            getRestUrl("uploadMetadataWithGifPreview"),
            { mint: metadataAccountData.mint }
          );
          const newUri = newUriResponse.data?.metadataArweaveLink;

          if (newUri == null) {
            notifyUnexpectedError();
            return;
          }
          const updateMetadataIx = createUpdateMetadataAccountInstruction(
            {
              metadata: metadataPda,
              updateAuthority: anchorWallet.publicKey,
            },
            {
              updateMetadataAccountArgs: {
                data: {
                  ...metadataData,
                  uri: newUri,
                },
                primarySaleHappened: metadata.primarySaleHappened,
                updateAuthority: anchorWallet.publicKey,
              },
            }
          );

          const txid = await sendTransactionWithWallet({
            connection,
            loggingData: {},
            txs: [ixToTx(updateMetadataIx)],
            wallet: anchorWallet,
          });

          if (txid != null) {
            commit({
              onCompleted: () => {
                setIsUpdated(true);
                setIsLoading(false);
              },
              onError: () => {
                notifyUnexpectedError();
                setIsLoading(false);
              },
              variables: {
                pk_columns: {
                  id: metadataPda.toString(),
                },
                set: {
                  uri: newUri,
                },
              },
            });
            return;
          }

          setIsLoading(false);
        }}
      >
        {isUpdated ? "Already updated" : "Update"}
      </ButtonWithText>
      <ListingCardForMetadata
        metadataAccount={metadataAccountData}
        desiredVideoQuality={VideoQuality.X2}
      />
    </div>
  );
}
