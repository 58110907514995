import Banner from "components/banner/Banner";
import TextButton from "components/buttons/TextButton";
import CreatorSurveyModal from "components/modal/CreatorSurveyModal";
import { useState } from "react";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import ElementId from "types/enums/ElementId";
import FontClass from "types/enums/FontClass";

type Props = {
  isBannerShown: boolean;
};

export default function CreatorSurveyBanner({ isBannerShown }: Props) {
  const [isCreatorSurveyModalShown, setIsCreatorSurveyModalShown] =
    useState(false);

  return (
    <>
      <CreatorSurveyModal
        isShown={isCreatorSurveyModalShown}
        modalId={ElementId.CreatorSurveyModalBanner}
        onHide={() => setIsCreatorSurveyModalShown(false)}
      />
      {isBannerShown && (
        <Banner backgroundColorClass={BackgroundColorClass.LightPurpleGradient}>
          <TextButton
            buttonThemeOrColorClass={ColorClass.DarkPurple}
            fontClass={FontClass.Body2}
            onClick={() => setIsCreatorSurveyModalShown(true)}
          >
            📣 Take our 2023 creator survey for a chance to win 100,000,000
            $BONK 📣
          </TextButton>
        </Banner>
      )}
    </>
  );
}
