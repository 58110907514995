import Banner from "components/banner/Banner";
import TextButton from "components/buttons/TextButton";
import CollectorSurveyModal from "components/modal/CollectorSurveyModal";
import { useState } from "react";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import ElementId from "types/enums/ElementId";
import FontClass from "types/enums/FontClass";

type Props = {
  isBannerShown: boolean;
};

export default function CollectorSurveyBanner({ isBannerShown }: Props) {
  const [isCollectorSurveyModalShown, setIsCollectorSurveyModalShown] =
    useState(false);

  return (
    <>
      <CollectorSurveyModal
        isShown={isCollectorSurveyModalShown}
        modalId={ElementId.CollectorSurveyModalBanner}
        onHide={() => setIsCollectorSurveyModalShown(false)}
      />
      {isBannerShown && (
        <Banner backgroundColorClass={BackgroundColorClass.LightPurpleGradient}>
          <TextButton
            buttonThemeOrColorClass={ColorClass.DarkPurple}
            fontClass={FontClass.Body2}
            onClick={() => setIsCollectorSurveyModalShown(true)}
          >
            📣 Take our 2023 collector survey for a chance to win 100,000,000
            $BONK 📣
          </TextButton>
        </Banner>
      )}
    </>
  );
}
