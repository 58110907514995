/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorValue {
  AliceBlue = "var(--color-aliceBlue)",
  AntiFlashWhite = "var(--color-antiFlashWhite)",
  ArtistPillShadow = "var(--color-artistPillShadow)",
  BackgroundOverlay = "var(--color-backgroundOverlay)",
  BackgroundOverlayDark = "var(--color-backgroundOverlayDark)",
  BrightGray = "var(--color-brightGray)",
  BrightPurple = "var(--color-brightPurple)",
  CadmiumGreen = "var(--color-cadmiumGreen)",
  CardBackground = "var(--color-cardBackground)",
  ClickableAreaBackground = "var(--color-clickableAreaBackground)",
  Cultured = "var(--color-cultured)",
  DarkCerulean = "var(--color-darkCerulean)",
  DarkGunmetal = "var(--color-darkGunmetal)",
  DarkPurple = "var(--color-darkPurple)",
  Dimmer = "var(--color-dimmer)",
  Error = "var(--color-error)",
  FloatingElement = "var(--color-floatingElement)",
  Ghost = "var(--color-ghost)",
  Green = "var(--color-green)",
  GreenishGray = "var(--color-greenishGray)",
  LightBlue = "var(--color-lightBlue)",
  LightPink = "var(--color-lightPink)",
  LightPurple = "var(--color-lightPurple)",
  LightPurpleHover = "var(--color-lightPurpleHover)",
  LinkWater = "var(--color-linkWater)",
  MaximumRed = "var(--color-maximumRed)",
  MediumBlue = "var(--color-mediumBlue)",
  MenuPopup = "var(--color-menuPopup)",
  MidnightBlue = "var(--color-midnightBlue)",
  Navy = "var(--color-navy)",
  NavyHover = "var(--color-navyHover)",
  OverlayButton = "var(--color-overlayButton)",
  PhantomPurple = "var(--color-phantomPurple)",
  Pink = "var(--color-pink)",
  PopheadzDarkGray = "var(--color-popheadzDarkGray)",
  PopheadzLightGray = "var(--color-popheadzLightGray)",
  Primary = "var(--color-primary)",
  ProgressBar = "var(--color-progressBar)",
  Purple = "var(--color-purple)",
  PurpleGradientHover = "var(--color-purpleGradientHover)",
  Red = "var(--color-red)",
  RedHover = "var(--color-redHover)",
  Scheme11 = "var(--color-scheme11)",
  Scheme12 = "var(--color-scheme12)",
  Scheme21 = "var(--color-scheme21)",
  Scheme22 = "var(--color-scheme22)",
  Scheme31 = "var(--color-scheme31)",
  Scheme32 = "var(--color-scheme32)",
  Scheme41 = "var(--color-scheme41)",
  Scheme42 = "var(--color-scheme42)",
  Scheme51 = "var(--color-scheme51)",
  Scheme52 = "var(--color-scheme52)",
  Scheme61 = "var(--color-scheme61)",
  Scheme62 = "var(--color-scheme62)",
  Seashell = "var(--color-seashell)",
  Secondary = "var(--color-secondary)",
  Shader = "var(--color-shader)",
  Sinopia = "var(--color-sinopia)",
  SkeletonBase = "var(--color-skeletonBase)",
  SkeletonHighlight = "var(--color-skeletonHighlight)",
  SpotlightOverlay = "var(--color-spotlightOverlay)",
  Tertiary = "var(--color-tertiary)",
  Transparent = "var(--color-transparent)",
  WebsiteBackground = "var(--color-websiteBackground)",
  White = "var(--color-white)",
  Yellow = "var(--color-yellow)",
}

export default ColorValue;
