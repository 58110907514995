import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import RefundsBody from "components/pages/refunds/RefundsBody";
import RefundsHeader from "components/pages/refunds/RefundsHeader";
import styles from "css/pages/refunds/RefundsPage.module.css";
import { Suspense } from "react";
import ColorValue from "types/enums/ColorValue";

export default function RefundsPage() {
  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <RefundsHeader />
          <div className={styles.refundsBody}>
            <Suspense
              fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
            >
              <RefundsBody />
            </Suspense>
          </div>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
