/**
 * @generated SignedSource<<57ef1401e80c9402b5a7606070ad9ee0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BonkClaimPageQuery$variables = {
  userId: string;
};
export type BonkClaimPageQuery$data = {
  readonly BonkClaim_by_pk: {
    readonly claimed: boolean | null;
    readonly id: string;
    readonly timeClaimed: string | null;
  } | null;
};
export type BonkClaimPageQuery = {
  response: BonkClaimPageQuery$data;
  variables: BonkClaimPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "BonkClaim",
    "kind": "LinkedField",
    "name": "BonkClaim_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "claimed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeClaimed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BonkClaimPageQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BonkClaimPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "08a24bc1f78676536bd331e08e799d41",
    "id": null,
    "metadata": {},
    "name": "BonkClaimPageQuery",
    "operationKind": "query",
    "text": "query BonkClaimPageQuery(\n  $userId: String!\n) {\n  BonkClaim_by_pk(userId: $userId) {\n    id\n    claimed\n    timeClaimed\n  }\n}\n"
  }
};
})();

(node as any).hash = "50e957f216c2f956bfc6dc1b8365c14d";

export default node;
