import getLdBootstrap from "utils/launch-darkly/getLdBootstrap";

// Keep default wallet addresses in sync with server/src/utils/auth/isEmployee.ts
export default function getEmployeeWallets() {
  // Defaults are set to make it easier on local dev
  return (
    getLdBootstrap()?.teamWallets ?? [
      "C7XtWMtkZWeBeQMgisTdwyxAxfYkyMopaDWi8TWB6w2E",
      "7GLfUpbAgwk8RM7CL5nHWMBrCgxXvaP8SpZXPvWyrxCg",
      "5BNvH7hxxeMfJkBYpFArEAy5iaYCypjxVFb1KtbZSLk4",
      "7B24ixFVAsgciqvfyBEnUvDsZPB9Q4UKyNj795rF7XTU",
      "8KUU21MBhtJBXnPMEpq7HZ3egoymHopizCydw12uNKfC",
      "5rRMBTpDLUJMEQKjqn2PTwZezZz8xFtgkFqCYSM4vTqA",
      "Abd91Y6QkKux6gpocZXRiQw5cVv7mTmqHb1Xs4GxEvRZ",
    ]
  );
}
