import {
  CreatorSurveyContext,
  CreatorSurveyContextData,
} from "context/CreatorSurveyContext";

import { useContext } from "react";

export default function useCreatorSurveyContext(): CreatorSurveyContextData {
  return useContext(CreatorSurveyContext);
}
