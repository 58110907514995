import Body1 from "components/text/Body1";
import Header2 from "components/text/Header2";
import styles from "css/pages/refunds/RefundsHeader.module.css";
import ColorClass from "types/enums/ColorClass";

export default function RefundsHeader() {
  return (
    <div className={styles.container}>
      <Header2 colorClass={ColorClass.Primary} textAlign="center">
        Refunds Tracker
      </Header2>
      <Body1
        className={styles.description}
        colorClass={ColorClass.Secondary}
        textAlign="center"
      >
        If you get outbid, you will be automatically refunded. However, in
        occasional circumstances, the automatic refund may not go through. This
        page shows any pieces where you have outstanding bids that haven&apos;t
        been refunded yet.
      </Body1>
    </div>
  );
}
