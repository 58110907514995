import ColorValue from "types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export default function CreatedIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0008 3.00006C17.2634 2.73741 17.5752 2.52907 17.9184 2.38693C18.2615 2.24479 18.6293 2.17163 19.0008 2.17163C19.3722 2.17163 19.74 2.24479 20.0832 2.38693C20.4263 2.52907 20.7381 2.73741 21.0008 3.00006C21.2634 3.2627 21.4718 3.57451 21.6139 3.91767C21.756 4.26083 21.8292 4.62862 21.8292 5.00006C21.8292 5.37149 21.756 5.73929 21.6139 6.08245C21.4718 6.42561 21.2634 6.73741 21.0008 7.00006L11.8 16.2C11.8 16.2 11.6254 15.1755 10.47 13.92C9.31465 12.6645 7.80078 12.2 7.80078 12.2L17.0008 3.00006Z"
        stroke={colorValue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7779 15.5312C4.43474 15.6734 4.22646 15.8158 3.86029 16.1443C1.8537 17.945 2.2266 21.8159 2.2266 21.8159C2.2266 21.8159 3.22656 21.3159 5.22656 21.3159C7.22656 21.3159 7.97342 20.2267 8.22656 19.8159C8.47971 19.4051 8.72656 18.8159 8.68872 18.1443C8.68872 17.7729 8.61556 17.4051 8.47342 17.062C8.33128 16.7188 8.12294 16.407 7.86029 16.1443C7.59765 15.8817 7.28584 15.6734 6.94268 15.5312C6.59952 15.3891 6.23173 15.3159 5.86029 15.3159C5.48886 15.3159 5.12106 15.3891 4.7779 15.5312Z"
        stroke={colorValue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
