/**
 * @generated SignedSource<<e0ed721d776054f9ed708ab352558230>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type NftStatusExpress_enum = "AirdropCompleted" | "AirdropInProgress" | "Auction" | "Burned" | "Listed" | "ListedEditions" | "ListedInstantSale" | "ListingScheduled" | "Owned" | "OwnedStoppedMintingForEditions" | "SoldOutEditions" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NftEditionsTableEditionsForMasterEditionMint_Query$data = {
  readonly editionsForMasterEditionMint: {
    readonly editions: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly nft: {
            readonly mint: string;
            readonly price: number | null;
            readonly status: NftStatusExpress_enum;
          };
          readonly " $fragmentSpreads": FragmentRefs<"NftEditionsTableRow_MetadataAccount">;
        };
      }>;
      readonly totalCount: number;
    };
  };
  readonly " $fragmentType": "NftEditionsTableEditionsForMasterEditionMint_Query";
};
export type NftEditionsTableEditionsForMasterEditionMint_Query$key = {
  readonly " $data"?: NftEditionsTableEditionsForMasterEditionMint_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"NftEditionsTableEditionsForMasterEditionMint_Query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "editionsForMasterEditionMint",
  "editions"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./EditionsForMasterEditionMintPaginationQuery.graphql')
    }
  },
  "name": "NftEditionsTableEditionsForMasterEditionMint_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EditionsForMasterEditionMintResponse",
      "kind": "LinkedField",
      "name": "editionsForMasterEditionMint",
      "plural": false,
      "selections": [
        {
          "alias": "editions",
          "args": [
            {
              "kind": "Variable",
              "name": "input",
              "variableName": "input"
            }
          ],
          "concreteType": "MetadataAccountsConnection",
          "kind": "LinkedField",
          "name": "__EditionsForMasterEditionMint_Query_editions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MetadataAccountsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MetadataAccount",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NftExpress",
                      "kind": "LinkedField",
                      "name": "nft",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mint",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "price",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "NftEditionsTableRow_MetadataAccount"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "539f1ee1b18279c79fe80aab53740a3b";

export default node;
