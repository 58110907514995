import ColorValue from "types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export default function VerifiedCheckmarkIcon({
  colorValue,
}: Props): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6358 6.14464C13.8206 6.14959 13.9942 6.21558 14.1273 6.34234L14.7721 6.97283C15.0731 7.26649 15.0343 7.77902 14.686 8.11997L9.02368 13.6462C8.7486 13.9148 8.22841 13.9341 7.93187 13.6462L5.31279 11.0887C4.96454 10.7483 4.92768 10.2364 5.22834 9.94325L5.87149 9.31331C6.17408 9.01965 6.69839 9.0554 7.04638 9.39443L8.47764 10.7923L12.9505 6.4262C13.1483 6.2351 13.3992 6.13941 13.6358 6.14464ZM12.2931 0.00114155C11.9234 -0.0117817 11.5338 0.0850053 11.1385 0.292327C10.2731 0.746566 9.61979 0.653354 8.85891 0.292327C8.45921 0.102328 8.06529 0.00774085 7.69833 0.0184644C7.08516 0.0377118 6.5394 0.356669 6.13117 1.02153C5.61374 1.86457 4.99204 2.07629 4.15551 2.16263C2.95312 2.28443 2.18811 2.94462 2.15537 4.15996C2.12951 5.14817 1.69818 5.64118 1.01625 6.13419C0.0361212 6.8414 -0.293155 7.79359 0.285623 8.86347C0.756843 9.73126 0.631129 10.3725 0.285623 11.1404C-0.208704 12.2447 -0.0221967 13.2326 1.01625 13.8697C1.85718 14.3875 2.06927 15.0056 2.15537 15.8437C2.27834 17.0455 2.93936 17.8086 4.15551 17.8427C5.14252 17.8707 5.63849 18.2986 6.13117 18.9818C6.86179 19.9984 7.7715 20.2761 8.85891 19.7138C9.73478 19.262 10.3719 19.3583 11.1385 19.7138C12.2474 20.2304 13.1387 19.9984 13.8679 18.9818C14.3606 18.2988 14.8566 17.8707 15.8436 17.8427C17.06 17.8088 17.7208 17.0458 17.8437 15.8437C17.9298 15.0056 18.1419 14.3875 18.9828 13.8697C20.0202 13.2329 20.2094 12.2447 19.7135 11.1404C19.368 10.3725 19.2434 9.73126 19.7135 8.86347C20.2936 7.79359 19.9643 6.8414 18.9828 6.13419C18.2995 5.64118 17.8712 5.14817 17.8437 4.15996C17.8121 2.94462 17.046 2.28471 15.8436 2.16263C15.0054 2.07629 14.3865 1.86457 13.8679 1.02153C13.4611 0.356669 12.9065 0.0231386 12.2931 0.00114155Z"
        fill={colorValue}
      />
    </svg>
  );
}
