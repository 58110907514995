import graphql from "babel-plugin-relay/macro";
import RefundsRow from "components/pages/refunds/RefundsRow";
import { RefundsBodyQuery } from "components/pages/refunds/__generated__/RefundsBodyQuery.graphql";
import Header3 from "components/text/Header3";
import styles from "css/pages/refunds/RefundsBody.module.css";
import useUserContext from "hooks/useUserContext";
import { useLazyLoadQuery } from "react-relay";
import ColorClass from "types/enums/ColorClass";

const query = graphql`
  query RefundsBodyQuery($input: RefundableAmountsInput!) {
    refundableAmounts(input: $input) {
      amounts {
        amountInLamports
        ...RefundsRow_RefundableAmount
      }
    }
  }
`;

export default function RefundsBody() {
  const { user } = useUserContext();
  const data = useLazyLoadQuery<RefundsBodyQuery>(query, {
    input: { userId: user?.id ?? "" },
  });
  const amounts = data.refundableAmounts.amounts.filter(
    (amount) => Number(amount.amountInLamports) > 0
  );

  if (amounts.length === 0) {
    return (
      <Header3 colorClass={ColorClass.Primary} textAlign="center">
        Nothing needs to be refunded!
      </Header3>
    );
  }

  return (
    <div className={styles.rows}>
      {amounts.map((amount) => (
        <RefundsRow refundableAmount={amount} />
      ))}
    </div>
  );
}
