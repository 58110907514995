import { Context, createContext, useMemo, useState } from "react";
import emptyFunction from "formfn-shared/dist/utils/emptyFunction";
import { Maybe } from "formfn-shared/dist/types/UtilityTypes";

export type CollectorSurveyContextData = {
  freeform: string;
  recommend: Maybe<number>;
  seanEllis: string;
  setFreeform: (val: string) => void;
  setRecommend: (val: number) => void;
  setSeanEllis: (val: string) => void;
  setWhy1: (val: Array<string>) => void;
  setWhy2: (val: string) => void;
  why1: Array<string>;
  why2: string;
};

export const CollectorSurveyContext: Context<CollectorSurveyContextData> =
  createContext<CollectorSurveyContextData>({
    freeform: "",
    recommend: null,
    seanEllis: "",
    setFreeform: emptyFunction,
    setRecommend: emptyFunction,
    setSeanEllis: emptyFunction,
    setWhy1: emptyFunction,
    setWhy2: emptyFunction,
    why1: [],
    why2: "",
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function CollectorSurveyContextProvider(
  props: ProviderProps
): JSX.Element {
  const [freeform, setFreeform] = useState<string>("");
  const [recommend, setRecommend] = useState<Maybe<number>>(null);
  const [seanEllis, setSeanEllis] = useState<string>("");
  const [why1, setWhy1] = useState<Array<string>>([]);
  const [why2, setWhy2] = useState<string>("");

  const value = useMemo(
    () => ({
      freeform,
      recommend,
      seanEllis,
      setFreeform,
      setRecommend,
      setSeanEllis,
      setWhy1,
      setWhy2,
      why1,
      why2,
    }),
    [freeform, recommend, seanEllis, why1, why2]
  );

  return (
    <CollectorSurveyContext.Provider value={value}>
      {props.children}
    </CollectorSurveyContext.Provider>
  );
}
