/**
 * @generated SignedSource<<c952d38366251593cf2217c2f00a0144>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BonkClaimPageMutation$variables = {
  userId: string;
};
export type BonkClaimPageMutation$data = {
  readonly claimBonk: string;
};
export type BonkClaimPageMutation = {
  response: BonkClaimPageMutation$data;
  variables: BonkClaimPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "kind": "ScalarField",
    "name": "claimBonk",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BonkClaimPageMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BonkClaimPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ad25f7692d461ddf588d66213b45e11",
    "id": null,
    "metadata": {},
    "name": "BonkClaimPageMutation",
    "operationKind": "mutation",
    "text": "mutation BonkClaimPageMutation(\n  $userId: String!\n) {\n  claimBonk(userId: $userId)\n}\n"
  }
};
})();

(node as any).hash = "1b4f5f95e86d49da7dd78b35ea3d9442";

export default node;
