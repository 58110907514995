import graphql from "babel-plugin-relay/macro";
import ButtonWithText from "components/buttons/ButtonWithText";
import TextButton from "components/buttons/TextButton";
import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import CheckmarkGradientIcon from "components/icons/CheckmarkGradientIcon";
import FlexBox from "components/layout/FlexBox";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { BonkClaimPageMutation } from "components/pages/bonk/__generated__/BonkClaimPageMutation.graphql";
import {
  BonkClaimPageQuery,
  BonkClaimPageQuery$data,
} from "components/pages/bonk/__generated__/BonkClaimPageQuery.graphql";
import Body1 from "components/text/Body1";
import Body1Medium from "components/text/Body1Medium";
import Header2 from "components/text/Header2";
import { notify } from "components/toast/notifications";
import notifyUnexpectedError from "components/toast/notifyUnexpectedError";
import HELP_LINK from "constants/HelpLink";
import useFlagsTyped from "hooks/useFlagsTyped";
import useUserContext from "hooks/useUserContext";
import { Suspense } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import dayjs from "utils/dates/dayjsex";
import formatDayjsDateAsDateAtTime from "utils/dates/formatDayjsDateAsDateAtTime";

const mutation = graphql`
  mutation BonkClaimPageMutation($userId: String!) {
    claimBonk(userId: $userId)
  }
`;

const query = graphql`
  query BonkClaimPageQuery($userId: String!) {
    BonkClaim_by_pk(userId: $userId) {
      id
      claimed
      timeClaimed
    }
  }
`;

const CLAIM_DEADLINE_DEFAULT = dayjs("2023-01-09T00:00:00-0800");

function Layout({ children }: { children: any }) {
  return (
    <FlexBox flexDirection="column" alignItems="center" gap={16}>
      {children}
    </FlexBox>
  );
}

function Content({ queryData }: { queryData: BonkClaimPageQuery$data }) {
  const { user } = useUserContext();
  const [commit, inFlight] = useMutation<BonkClaimPageMutation>(mutation);
  const { bonkClaimConfig } = useFlagsTyped();
  const deadlineAsDayjs =
    bonkClaimConfig?.claimDeadline != null
      ? dayjs(bonkClaimConfig.claimDeadline)
      : CLAIM_DEADLINE_DEFAULT;

  if (queryData.BonkClaim_by_pk == null) {
    return (
      <Layout>
        <Header2 colorClass={ColorClass.Primary}>Claim your $BONK</Header2>
        <Body1 colorClass={ColorClass.Primary} textAlign="center">
          Unfortunately you are not eligible to make a claim. If you think this
          is a mistake, please reach out to our team via our{" "}
          <TextButton
            buttonThemeOrColorClass={ColorClass.BrightPurple}
            display="inline"
            fontClass={FontClass.NavLink}
            href={HELP_LINK}
            type="link_external"
          >
            help center.
          </TextButton>
        </Body1>
      </Layout>
    );
  }

  const { id: bonkId, claimed, timeClaimed } = queryData.BonkClaim_by_pk!;
  if (deadlineAsDayjs.isBefore(dayjs()) && claimed === false) {
    return (
      <Layout>
        <Header2 colorClass={ColorClass.Primary}>Claim your $BONK</Header2>
        <FlexBox flexDirection="row" gap={4}>
          <Body1 colorClass={ColorClass.Primary}>
            The deadline to make a claim was by
          </Body1>
          <Body1Medium colorClass={ColorClass.Primary}>
            {formatDayjsDateAsDateAtTime(deadlineAsDayjs)}
          </Body1Medium>
        </FlexBox>
        <Body1 colorClass={ColorClass.Primary}>Claims are now closed.</Body1>
      </Layout>
    );
  }

  return (
    <Layout>
      <Header2 colorClass={ColorClass.Primary}>Claim your $BONK</Header2>
      {claimed === false ? (
        <Body1 colorClass={ColorClass.Primary} textAlign="center">
          You are eligible to make a claim. Please click the button below to
          make your claim and we will record this in our system.
        </Body1>
      ) : (
        <>
          <Body1 colorClass={ColorClass.Primary} textAlign="center">
            We recorded your claim.
          </Body1>
          <FlexBox flexDirection="row" gap={4} alignItems="center">
            <CheckmarkGradientIcon />
            <Body1 colorClass={ColorClass.Primary}>
              Claimed at {formatDayjsDateAsDateAtTime(dayjs(timeClaimed))}
            </Body1>
          </FlexBox>
        </>
      )}
      {claimed === false && (
        <Body1 colorClass={ColorClass.Primary} textAlign="center">
          Your $BONK will be transferred to you shortly after you make your
          claim.
        </Body1>
      )}
      {claimed === false && (
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          disabled={inFlight}
          isLoading={inFlight}
          onClick={() => {
            commit({
              onCompleted: () => {
                notify({ message: "Claim submitted!" });
              },
              onError: () => {
                notifyUnexpectedError();
              },
              updater: (store, response) => {
                const bonkClaim = store.get(bonkId);
                bonkClaim?.setValue(true, "claimed");
                bonkClaim?.setValue(response.claimBonk, "timeClaimed");
              },
              variables: {
                userId: user!.id,
              },
            });
          }}
        >
          Claim
        </ButtonWithText>
      )}
    </Layout>
  );
}

function DataLoader() {
  const { user } = useUserContext();
  const queryData = useLazyLoadQuery<BonkClaimPageQuery>(query, {
    userId: user!.id,
  });

  return <Content queryData={queryData} />;
}

export default function BonkClaimPage() {
  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <Suspense
            fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
          >
            <DataLoader />
          </Suspense>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
