import Environment from "formfn-shared/dist/types/Environment";
import getEnvironment from "utils/getEnvironment";

export default function getLaunchDarklyClientSideId() {
  switch (getEnvironment()) {
    case Environment.Testnet:
      return "625e5abe2ce77f15296fb11b";
    case Environment.Local:
      return "621878c57e41e9154f5dcc5b";
    case Environment.Development:
      return "621816bfd979190cad34ede8";
    case Environment.Production:
    default:
      return "621816bfd979190cad34ede9";
  }
}
