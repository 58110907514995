export default function ExchangeArtIcon() {
  return (
    <svg
      _ngcontent-kma-c193=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 276.675 46.174"
      width="100px"
      height="25px"
    >
      <path
        _ngcontent-kma-c193=""
        d="M31.1 34.5H15.484V11.805h15.434l-.552 4.9H22.1v3.673h6.552v4.9H22.1V29.6h9zM47.668 34.5L43.6 27.483 39.339 34.5h-7.166l7.717-11.947-6.982-10.748h7.5l3.4 5.941 3.583-5.941h7.2l-7.074 10.87L55.17 34.5z"
        className="fill-current fill-logo"
      />
      <path
        _ngcontent-kma-c193=""
        d="M72.563 27.912l.643 5.512a10.392 10.392 0 01-3.061 1.076 19.164 19.164 0 01-3.92.4q-5.482 0-8.437-3.093t-2.955-8.6a13.694 13.694 0 011.348-6.308 9.474 9.474 0 013.9-4.057 12.427 12.427 0 016.14-1.424 17.011 17.011 0 013.9.428 11.81 11.81 0 013.322 1.317l-1.408 5.206a8.606 8.606 0 00-2.358-1.547 6.688 6.688 0 00-2.633-.5 4.787 4.787 0 00-4.012 1.761 8.018 8.018 0 00-1.37 5.017 8.025 8.025 0 001.424 5.1 4.985 4.985 0 004.149 1.8 7.6 7.6 0 003.047-.614 7.21 7.21 0 002.281-1.474zM82.607 34.5h-6.614V11.805h6.614v8.084h5.941v-8.084h6.614V34.5h-6.614v-9.4h-5.941zM121.007 34.5h-6.768q-.612-2.175-1.224-4.288h-7.442q-.612 2.114-1.224 4.288h-6.615q1.9-5.666 3.843-11.346t4-11.345h7.6q2.082 5.666 4.026 11.345t3.804 11.346zm-13.964-9.187h4.532q-.551-1.868-1.133-3.751t-1.132-3.752q-.583 1.869-1.149 3.752t-1.118 3.747zM143.177 34.5H137.7l-8.3-12.708V34.5h-5.818V11.805h6.339l7.44 11.3v-11.3h5.819zM166.021 21.083v11.79a5.427 5.427 0 01-1.869 1.041 15.6 15.6 0 01-2.847.719 20.345 20.345 0 01-3.276.261 13.779 13.779 0 01-6.4-1.378 9.359 9.359 0 01-4.058-4 13.3 13.3 0 01-1.393-6.323 13.167 13.167 0 011.424-6.323 9.691 9.691 0 014.073-4.058 13.361 13.361 0 016.354-1.408 17.806 17.806 0 014.073.459 12.538 12.538 0 013.4 1.286l-1.408 5.206a9.879 9.879 0 00-2.617-1.516 8.17 8.17 0 00-2.955-.536q-5.514 0-5.512 6.8 0 6.891 5.664 6.891a10.023 10.023 0 001.348-.093v-3.918h-3.4v-4.9zM184.792 34.5h-15.617V11.805h15.433l-.55 4.9h-8.269v3.673h6.554v4.9h-6.554V29.6h9z"
        className="fill-current fill-logo"
      />
      <path
        _ngcontent-kma-c193=""
        d="M191.069 34.894a3.4 3.4 0 01-2.471-.98 3.419 3.419 0 010-4.838 3.62 3.62 0 014.956 0 3.455 3.455 0 010 4.838 3.388 3.388 0 01-2.485.98z"
        fill="#d1dd26"
      />
      <path
        _ngcontent-kma-c193=""
        d="M220.006 34.5h-6.768q-.612-2.175-1.224-4.288h-7.442q-.612 2.114-1.224 4.288h-6.615q1.9-5.666 3.843-11.346t4-11.345h7.595q2.082 5.666 4.026 11.345t3.809 11.346zm-13.964-9.187h4.532q-.551-1.868-1.133-3.751t-1.132-3.752q-.584 1.869-1.149 3.752t-1.118 3.747zM229.192 34.5h-6.614V11.805h7.288a17.2 17.2 0 015.282.735 7.953 7.953 0 013.583 2.189 5.242 5.242 0 011.3 3.629 5.059 5.059 0 01-1.011 3.2 6.405 6.405 0 01-2.847 2.006 9.486 9.486 0 013.23 3.567 41.609 41.609 0 013.139 7.365h-7.472q-.951-2.756-1.715-4.4a12.172 12.172 0 00-1.439-2.465 3.47 3.47 0 00-1.332-1.087 4.275 4.275 0 00-1.394-.291zm1.008-17.887h-1.011v5.144h1.011a3.625 3.625 0 002.419-.751 2.393 2.393 0 00.888-1.914 2.14 2.14 0 00-.888-1.821 3.978 3.978 0 00-2.419-.658zM254.854 34.5h-6.615V16.735h-6.185v-4.93h18.986v4.93h-6.186z"
        className="fill-current fill-logo"
      />
      <path
        _ngcontent-kma-c193=""
        d="M275.21 46.174H1.465A1.467 1.467 0 010 44.708V1.465A1.466 1.466 0 011.465 0H275.21a1.466 1.466 0 011.465 1.465v43.243a1.467 1.467 0 01-1.465 1.466zM1.465 1A.465.465 0 001 1.465v43.243a.466.466 0 00.465.466H275.21a.466.466 0 00.465-.466V1.465A.465.465 0 00275.21 1z"
        className="fill-current fill-logo"
      />
    </svg>
  );
}
