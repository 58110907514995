/**
 * @generated SignedSource<<fbbb6b76b851d9bab66baf9fa6b93379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubmitCollectorSurveyInput = {
  freeform?: string | null;
  recommend: number;
  seanEllis: string;
  why1: string;
  why2: string;
};
export type CollectorSurveyModalMutation$variables = {
  input: SubmitCollectorSurveyInput;
};
export type CollectorSurveyModalMutation$data = {
  readonly SurveyMutations: {
    readonly submitCollectorSurvey: {
      readonly user: {
        readonly hasTakenCollectorSurvey2023: boolean | null;
      };
    };
  };
};
export type CollectorSurveyModalMutation = {
  response: CollectorSurveyModalMutation$data;
  variables: CollectorSurveyModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTakenCollectorSurvey2023",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectorSurveyModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SurveyMutationsResponse",
        "kind": "LinkedField",
        "name": "SurveyMutations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SubmitCollectorSurveyResponse",
            "kind": "LinkedField",
            "name": "submitCollectorSurvey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserExpress",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectorSurveyModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SurveyMutationsResponse",
        "kind": "LinkedField",
        "name": "SurveyMutations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SubmitCollectorSurveyResponse",
            "kind": "LinkedField",
            "name": "submitCollectorSurvey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserExpress",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c82c55abf800aacfea474ab3b9061691",
    "id": null,
    "metadata": {},
    "name": "CollectorSurveyModalMutation",
    "operationKind": "mutation",
    "text": "mutation CollectorSurveyModalMutation(\n  $input: SubmitCollectorSurveyInput!\n) {\n  SurveyMutations {\n    submitCollectorSurvey(input: $input) {\n      user {\n        hasTakenCollectorSurvey2023\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f86fb498965d77bf8aa19901a0549ec6";

export default node;
