import ColorValue from "types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export default function SolscanIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0644 0.114399C6.21737 0.708772 2.80016 3.28533 1.11971 6.85858C0.688627 7.77526 0.421533 8.58302 0.183776 9.68984C-0.0425494 10.7432 -0.0639627 13.0674 0.14255 14.169C0.735112 17.3299 2.5621 20.1214 5.18733 21.877C7.27196 23.271 9.69044 24.0007 12.2247 24C13.9716 23.9995 15.2125 23.7584 16.5452 23.1604C17.0828 22.9192 18.0505 22.3193 18.1427 22.1702C18.2166 22.0505 15.6436 19.4522 15.3058 19.3055C14.9594 19.155 14.7227 19.1708 13.7854 19.4068C13.0646 19.5883 12.8202 19.6136 11.8552 19.6075C10.632 19.5998 10.0695 19.488 8.9883 19.0378C7.76241 18.5274 6.39325 17.3939 5.65644 16.2795C5.2127 15.6082 4.77956 14.6267 4.5639 13.8037C4.40715 13.2056 4.36874 12.8743 4.36326 12.0732C4.35952 11.5283 4.39984 10.8767 4.45287 10.6252C5.09443 7.58435 7.18242 5.36385 10.1653 4.55006C11.0428 4.31076 12.8511 4.3106 13.7938 4.54991C15.1364 4.89065 16.4514 5.64057 17.4195 6.61745C19.9792 9.20049 20.382 13.1572 18.3958 16.2081L18.0854 16.685L18.4153 17.0465C18.5968 17.2453 19.2611 17.9502 19.8915 18.6128L21.0377 19.8175L21.3727 19.4511C24.1495 16.4127 24.8051 11.2967 22.956 7.09469C21.3424 3.42777 17.9929 0.824156 14.0139 0.143892C12.9647 -0.0355095 11.1237 -0.0492276 10.0644 0.114399ZM10.8273 7.35182C9.20922 7.76062 7.88228 9.05294 7.38817 10.7014C7.19987 11.3294 7.19987 12.6646 7.38817 13.2926C7.89455 14.9821 9.28016 16.3008 10.9125 16.6467C13.0592 17.1015 15.2144 16.1286 16.1824 14.2678C16.5787 13.5059 16.7299 12.8788 16.7299 11.997C16.7299 9.84204 15.5389 8.18543 13.4571 7.44503C12.8845 7.24139 11.4639 7.19102 10.8273 7.35182Z"
        fill={colorValue}
      />
    </svg>
  );
}
