/**
 * @generated SignedSource<<3aee48be57250c9367e2eb81938406b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSafetyCheckSubmissionInput = {
  artProcess: string;
  instagramName?: string | null;
  isCopyrightVerified: boolean;
  processVideo?: AssetInput | null;
  websiteUrl: string;
};
export type AssetInput = {
  arweaveTxid?: string | null;
  contentType: string;
  dimensions?: AssetDimensionsInput | null;
  downloadUrl: string;
  path: string;
};
export type AssetDimensionsInput = {
  height: number;
  width: number;
};
export type SafetyCheckFormCreateSubmissionMutation$variables = {
  input: CreateSafetyCheckSubmissionInput;
};
export type SafetyCheckFormCreateSubmissionMutation$data = {
  readonly SafetyCheckMutations: {
    readonly createSafetyCheckSubmission: {
      readonly id: string;
    };
  };
};
export type SafetyCheckFormCreateSubmissionMutation = {
  response: SafetyCheckFormCreateSubmissionMutation$data;
  variables: SafetyCheckFormCreateSubmissionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SafetyCheckMutationsResponse",
    "kind": "LinkedField",
    "name": "SafetyCheckMutations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "CreateSafetyCheckSubmissionResponse",
        "kind": "LinkedField",
        "name": "createSafetyCheckSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SafetyCheckFormCreateSubmissionMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SafetyCheckFormCreateSubmissionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "585132f9a18859bb020ef33f45904998",
    "id": null,
    "metadata": {},
    "name": "SafetyCheckFormCreateSubmissionMutation",
    "operationKind": "mutation",
    "text": "mutation SafetyCheckFormCreateSubmissionMutation(\n  $input: CreateSafetyCheckSubmissionInput!\n) {\n  SafetyCheckMutations {\n    createSafetyCheckSubmission(input: $input) {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "223adc770cbd7ea4be54a53029870bdb";

export default node;
