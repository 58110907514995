import assertUnreachable from "formfn-shared/dist/utils/assertUnreachable";
import { Flags } from "hooks/useFlagsTyped";
import Network from "types/enums/Network";
import getLdBootstrap from "utils/launch-darkly/getLdBootstrap";

export default function getRpcHostFromNetwork(
  network: Network,
  flagsOverride?: Flags
): string {
  const flags = flagsOverride ?? getLdBootstrap();
  switch (network) {
    case Network.Testnet:
      return (
        flags?.testnetRpcUrlFrontend ??
        "https://damp-billowing-grass.solana-testnet.quiknode.pro/dcf101dbb7894057d31374228c60c8c2d8994d75/"
      );
    case Network.Devnet:
      return (
        flags?.devnetRpcUrlFrontend ??
        "https://delicate-withered-firefly.solana-devnet.quiknode.pro/bae54999a60446c302dddc39c1b1cfeae031dabf/"
      );
    case Network.Mainnet:
      return (
        flags?.mainnetRpcUrlFrontend ??
        "https://solana-api.syndica.io/access-token/hFRDjmwQwiXvP4etqbjotkM1UXw20qlTny3Xb76k6uj4A4OQ80th19PI2yCQ2Qu7/rpc"
      );
    default:
      return assertUnreachable(network);
  }
}
