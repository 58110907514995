enum ExploreTab {
  // TODO[editions]: rename to one of one
  Artwork = "Artwork",
  Campaigns = "Campaigns",
  Creators = "Creators",
  Editions = "Editions",
  Series = "Series",
}

export default ExploreTab;
