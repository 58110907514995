import graphql from "babel-plugin-relay/macro";
import ButtonWithText from "components/buttons/ButtonWithText";
import CheckboxButton from "components/buttons/CheckboxButton";
import ConnectDiscordButton from "components/buttons/ConnectDiscordButton";
import ConnectInstagramButton from "components/buttons/ConnectInstagramButton";
import ConnectTwitterButton from "components/buttons/ConnectTwitterButton";
import TextButton from "components/buttons/TextButton";
import PlusIcon from "components/icons/PlusIcon";
import GenericDropzone from "components/input/GenericDropzone";
import InputLabel from "components/input/InputLabel";
import InputWithLabel from "components/input/InputWithLabel";
import TextArea from "components/input/TextArea";
import TextInput from "components/input/TextInput";
import FlexBox from "components/layout/FlexBox";
import DiscordAuthModals from "components/modal/DiscordAuthModals";
import { SafetyCheckFormCreateSubmissionMutation } from "components/pages/safety-check/__generated__/SafetyCheckFormCreateSubmissionMutation.graphql";
import { SafetyCheckForm_User$key } from "components/pages/safety-check/__generated__/SafetyCheckForm_User.graphql";
import ErrorMessage from "components/text/ErrorMessage";
import notifyUnexpectedError from "components/toast/notifyUnexpectedError";
import Video from "components/videos/Video";
import DEFAULT_ACCEPTED_MEDIA_TYPES from "constants/media-type/DefaultAcceptedMediaTypes";
import styles from "css/pages/safety-check/SafetyCheckForm.module.css";
import BYTES_PER_MEGABYTE from "formfn-shared/dist/constants/BytesPerMegabyte";
import { Maybe } from "formfn-shared/dist/types/UtilityTypes";
import useCheckSocialNetworkAuthError from "hooks/useCheckSocialNetworkAuthError";
import useDiscordAuthContext from "hooks/useDiscordAuthContext";
import useFlagsTyped from "hooks/useFlagsTyped";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";
import getArtistSubmissionAssetStoragePath from "utils/firebase/storage-paths/getArtistSubmissionAssetStoragePath";
import uploadFile from "utils/firebase/uploadFile";
import getFileExt from "utils/getFileExt";
import isValidHttpUrl from "utils/isValidHttpUrl";
import joinClasses from "utils/joinClasses";

const fragment = graphql`
  fragment SafetyCheckForm_User on User {
    id
    twitterName

    DiscordAuth {
      hasConnectedDiscordAccount
      hasJoinedDiscordServer
    }

    ...ConnectDiscordButton_User
    ...ConnectInstagramButton_User
    ...ConnectTwitterButton_User
    ...DiscordAuthModals_User
  }
`;

const createSubmissionMutation = graphql`
  mutation SafetyCheckFormCreateSubmissionMutation(
    $input: CreateSafetyCheckSubmissionInput!
  ) {
    SafetyCheckMutations {
      createSafetyCheckSubmission(input: $input) {
        id
      }
    }
  }
`;

function MediaInput({
  file,
  hasError,
  onChange,
}: {
  file: Maybe<File>;
  hasError: boolean;
  onChange: (val: File) => void;
}) {
  return (
    <GenericDropzone
      accept={DEFAULT_ACCEPTED_MEDIA_TYPES}
      disableHoverStyle
      maxSize={10 * BYTES_PER_MEGABYTE}
      onDropAccepted={(files) => {
        if (files.length === 0) {
          return;
        }

        onChange(files[0]);
      }}
    >
      <div
        className={joinClasses(
          styles.mediaInput,
          hasError ? styles.mediaInputError : null
        )}
      >
        {file == null ? (
          <PlusIcon colorValue={ColorValue.Ghost} />
        ) : file.type.includes("video") ? (
          <Video className={styles.image} src={URL.createObjectURL(file)} />
        ) : (
          <img className={styles.image} src={URL.createObjectURL(file)} />
        )}
      </div>
    </GenericDropzone>
  );
}

type Props = {
  onSubmitted: () => void;
  user: SafetyCheckForm_User$key;
};

export default function SafetyCheckForm({ onSubmitted, user }: Props) {
  const userData = useFragment(fragment, user);
  const { enableInstagramLinking } = useFlagsTyped();
  const { DiscordAuth } = userData;
  const { displayDiscordAuthConnectModal } = useDiscordAuthContext();
  useCheckSocialNetworkAuthError();
  const [commitCreateSubmission, commitCreateSubmissionInFlight] =
    useMutation<SafetyCheckFormCreateSubmissionMutation>(
      createSubmissionMutation
    );

  // Form state
  const [artProcess, setArtProcess] = useState("");
  const [instagramName, setInstagramName] = useState("");
  const [isCopyrightVerified, setIsCopyrightVerified] = useState(false);
  const [portfolioLink, setportfolioLink] = useState("");
  const [processVideoFile, setProcessVideoFile] = useState<Maybe<File>>(null);
  const [showErrors, setShowErrors] = useState(false);

  const errors = {
    artProcess: artProcess.length === 0,
    isCopyrightVerified: !isCopyrightVerified,
    portfolioLink: !isValidHttpUrl(portfolioLink),
    twitter: userData.twitterName == null,
  };

  const hasError = Object.values(errors).some(Boolean);

  return (
    <div className={styles.form}>
      <InputWithLabel
        input={
          <TextInput
            hasError={showErrors && errors.portfolioLink}
            onChange={setportfolioLink}
            placeholder="Link to your website"
            value={portfolioLink}
          />
        }
        label={
          <InputLabel label="Link to your art portfolio/website" required />
        }
      />
      <InputWithLabel
        input={
          <ConnectTwitterButton
            confirmationMessage="Connecting your Twitter will refresh this page, so make sure all your info been saved!"
            redirectLocation="Apply"
            user={userData}
          />
        }
        label={
          <InputLabel
            label="Link your Twitter (do this first!)"
            required
            subLabel="Make sure you're signed into the Twitter account you want to connect. This helps our artists verify that you are the actual creator of the work you're submitting."
          />
        }
      />
      <InputWithLabel
        input={
          <div className={styles.discordSection}>
            <DiscordAuthModals user={userData} />
            <ConnectDiscordButton user={userData} redirectLocation="Apply" />
            {DiscordAuth?.hasConnectedDiscordAccount === true &&
              DiscordAuth?.hasJoinedDiscordServer === false && (
                <TextButton
                  buttonThemeOrColorClass={TextButtonTheme.PurpleGradient}
                  display="inline"
                  fontClass={FontClass.Body2}
                  onClick={() =>
                    displayDiscordAuthConnectModal({
                      onlyDisplayJoinDiscordSteps: true,
                      redirectLocation: "Apply",
                    })
                  }
                >
                  Join Discord
                </TextButton>
              )}
          </div>
        }
        label={
          <InputLabel
            label="Link your Discord"
            subLabel="If you are accepted onto Formfunction, we'll add you to a special Discord channel for our verified creators."
          />
        }
      />
      {enableInstagramLinking ? (
        <InputWithLabel
          input={
            <ConnectInstagramButton
              confirmationMessage="Connecting your Instagram will refresh this page, so make sure all your info been saved!"
              redirectLocation="Apply"
              user={userData}
            />
          }
          label={
            <InputLabel
              label="Link your Instagram"
              subLabel="Make sure you're signed into the Instagram account you want to connect."
            />
          }
        />
      ) : (
        <InputWithLabel
          input={
            <TextInput
              className={styles.instagram}
              onChange={setInstagramName}
              permaPlaceholder="https://instagram.com/"
              value={instagramName}
            />
          }
          label={
            <InputLabel
              label="Link to your Instagram"
              subLabel="Just include your handle (not the entire link)."
            />
          }
        />
      )}
      <InputWithLabel
        input={
          <TextArea
            hasError={showErrors && errors.artProcess}
            maxLength={600}
            onChange={setArtProcess}
            placeholder="Write here..."
            rows={4}
            value={artProcess}
          />
        }
        label={
          <InputLabel
            label="Art process"
            required
            subLabel={
              "Tell us about your work and how you create it. " +
              "Please mention any applicable tools or software that you use."
            }
          />
        }
      />
      <InputWithLabel
        input={
          <MediaInput
            file={processVideoFile}
            // TODO[@arcticmatt] implement error UI
            hasError={false}
            onChange={setProcessVideoFile}
          />
        }
        label={
          <InputLabel
            label="Process video"
            subLabel={
              "Optionally, submit a video showing some of your art process " +
              "(e.g. a video of you drawing a piece, or a timelapse). Size limit is 25MB."
            }
          />
        }
      />
      <InputWithLabel
        input={
          <CheckboxButton
            isActive={isCopyrightVerified}
            onClick={() => setIsCopyrightVerified((val) => !val)}
          />
        }
        label={
          <InputLabel
            label={
              "Verify that the art you plan to mint belongs to you and only " +
              "you, with no infringement on copyright or others' identities?"
            }
            required
          />
        }
      />
      <FlexBox alignItems="center" flexDirection="column">
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.submitButton}
          disabled={errors.twitter || errors.isCopyrightVerified}
          fontClass={FontClass.NavLink}
          isLoading={commitCreateSubmissionInFlight}
          onClick={async () => {
            setShowErrors(true);

            if (hasError) {
              return;
            }

            const processVideoAssetInfo =
              processVideoFile == null
                ? null
                : await uploadFile(
                    processVideoFile,
                    getArtistSubmissionAssetStoragePath(
                      userData.id,
                      getFileExt(processVideoFile)
                    )
                  );

            commitCreateSubmission({
              onCompleted: () => {
                onSubmitted();
              },
              onError: (e) => {
                notifyUnexpectedError(e.message);
              },
              variables: {
                input: {
                  artProcess,
                  instagramName,
                  isCopyrightVerified,
                  processVideo:
                    processVideoAssetInfo == null
                      ? null
                      : {
                          contentType: processVideoAssetInfo.type,
                          dimensions: processVideoAssetInfo.dimensions,
                          downloadUrl: processVideoAssetInfo.downloadUrl,
                          path: processVideoAssetInfo.fileName,
                        },
                  websiteUrl: portfolioLink,
                },
              },
            });
          }}
        >
          Submit
        </ButtonWithText>
        {showErrors && hasError && (
          <ErrorMessage fontClass={FontClass.Body1}>
            Please make sure all inputs are valid
          </ErrorMessage>
        )}
      </FlexBox>
    </div>
  );
}
