/**
 * @generated SignedSource<<1f004f8085196f8dbbcbec17e444e307>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RefundsRow_RefundableAmount$data = {
  readonly amount: {
    readonly amount: number;
    readonly " $fragmentSpreads": FragmentRefs<"useAuctionHouseSdkForPrice_Price" | "useFormattedNftPrice_Price" | "useNftPriceSymbol_Price">;
  };
  readonly metadataAccount: {
    readonly contentType: string;
    readonly data: {
      readonly name: string;
    };
    readonly mint: string;
    readonly offchainData: {
      readonly image: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"useNftLinkForMetadataAccount_MetadataAccount">;
  };
  readonly " $fragmentType": "RefundsRow_RefundableAmount";
};
export type RefundsRow_RefundableAmount$key = {
  readonly " $data"?: RefundsRow_RefundableAmount$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefundsRow_RefundableAmount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefundsRow_RefundableAmount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "amount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useFormattedNftPrice_Price"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useNftPriceSymbol_Price"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useAuctionHouseSdkForPrice_Price"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetadataAccount",
      "kind": "LinkedField",
      "name": "metadataAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetadataAccountData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetadataOffchain",
          "kind": "LinkedField",
          "name": "offchainData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useNftLinkForMetadataAccount_MetadataAccount"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RefundableAmount",
  "abstractKey": null
};

(node as any).hash = "3ba2e3b0907cdb96220690f4897bd9e5";

export default node;
