/**
 * @generated SignedSource<<db7d54b0c92d3c20eab9f7b244dcac10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NftMetadata_set_input = {
  assetArweaveTxid?: string | null;
  assetHeight?: number | null;
  assetWidth?: number | null;
  contentType?: string | null;
  creators?: any | null;
  description?: string | null;
  editionNonce?: number | null;
  id?: string | null;
  image?: string | null;
  isMutable?: boolean | null;
  key?: number | null;
  mint?: string | null;
  name?: string | null;
  nonstandardAssetId?: string | null;
  sellerFeeBasisPoints?: number | null;
  symbol?: string | null;
  timeCreated?: string | null;
  updateAuthority?: string | null;
  uri?: string | null;
  videoPlaybackId?: string | null;
  videoPreviewPlaybackId?: string | null;
};
export type NftMetadata_pk_columns_input = {
  id: string;
};
export type UpdateMetadataListingCardMutation$variables = {
  pk_columns: NftMetadata_pk_columns_input;
  set: NftMetadata_set_input;
};
export type UpdateMetadataListingCardMutation$data = {
  readonly update_NftMetadata_by_pk: {
    readonly id: string;
  } | null;
};
export type UpdateMetadataListingCardMutation = {
  response: UpdateMetadataListingCardMutation$data;
  variables: UpdateMetadataListingCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pk_columns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "set"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "set"
      },
      {
        "kind": "Variable",
        "name": "pk_columns",
        "variableName": "pk_columns"
      }
    ],
    "concreteType": "NftMetadata",
    "kind": "LinkedField",
    "name": "update_NftMetadata_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMetadataListingCardMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateMetadataListingCardMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "531d24ac024c1c4e56580fdb626ae503",
    "id": null,
    "metadata": {},
    "name": "UpdateMetadataListingCardMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMetadataListingCardMutation(\n  $set: NftMetadata_set_input!\n  $pk_columns: NftMetadata_pk_columns_input!\n) {\n  update_NftMetadata_by_pk(_set: $set, pk_columns: $pk_columns) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f36ce95645ede8d6a251f4aad6adc4e";

export default node;
